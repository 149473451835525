import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import { useSelector } from 'react-redux'
import { setConnected, setDisconnected, switchTheme, saveUser } from '../actions/authActions'
import { connect } from 'react-redux'
import { Flexed, Spacer, Text } from '../styles/shared'
import { LibraryData } from '../contracts/Library'

const Libary = () => {
	let user = useSelector((state) => state.auth.user)
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	return (
		<Wrapper>
			<CustomRow>
				<Col lg={12}>
					<Form isDarkTheme={isDarkTheme}>
						<Row>
							<Col>
								<Heading isDarkTheme={isDarkTheme}>Libary</Heading>
								<Spacer height={1} />
							</Col>
							<Col>
								{LibraryData.map((val, i) => {
									return (<DocxCover lightHeading={val?.normalHeadingLight} key={i}>
										{val?.MainHeading && <DocxMainHeading isDarkTheme={isDarkTheme}>{val.MainHeading}</DocxMainHeading>}
										{val?.subHeading?.map((subHeading) => {
											return (<>
												<DocxSubHeading isDarkTheme={isDarkTheme}>{subHeading}</DocxSubHeading>
											</>
											);
										})}
										{val?.normalHeadingLight && <DocxNormalHeading lightHeading={true} isDarkTheme={isDarkTheme}>{val.normalHeadingLight}</DocxNormalHeading>}
										{val?.normalHeading?.map((normalHeading) => {
											return (<>
												<DocxNormalHeading isDarkTheme={isDarkTheme}>{normalHeading}</DocxNormalHeading>
											</>
											);
										})}
										{val?.paragraph?.map((para) => {
											return (<>
												<DocxPara>{para}</DocxPara>
											</>
											);
										})}
									</DocxCover>
									);
								})}
							</Col>
						</Row>
					</Form>
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		justify-content: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const Form = styled(Container)`
	padding: 2rem;
	${media.xs`
	padding:  2rem 0rem;
	`}
`

const Heading = styled.h2`
	font-family: 'Montserrat-Regular', sans-serif;
	line-height: 1.85rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.golden : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
`
const DocxCover = styled.div`
	margin-bottom: ${({ lightHeading }) => (lightHeading ? '0rem' : '1.8rem')};
`;

const DocxMainHeading = styled.h2`
	font-family: 'Montserrat-Regular', sans-serif;
	line-height: 1.85rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${palette.golden};
	margin: 3rem 0rem 0.5rem 0rem;
	font-size: 1.5rem;
	font-weight: bold;
`

const DocxSubHeading = styled.p`
	line-height: 1.5rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${palette.white};
	margin-bottom: 0.5rem;
	font-size: 0.875rem;
	font-weight: bold;
	opacity: 0.5;
`

const DocxNormalHeading = styled.h2`
	font-family: 'Montserrat-Regular', sans-serif;
	line-height: 1.85rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${palette.golden};
	opacity: ${({ lightHeading }) => (lightHeading ? '0.7' : '1')};
	font-size: 1rem;
	font-weight: bold;
`
const DocxPara = styled.p`
	line-height: 1.5rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${palette.white};
	margin-bottom: 0.5rem !important;
	font-size: 0.875rem;
	font-weight: bold;
`

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => ({
	setConnected: (data) => dispatch(setConnected(data)),
	setDisconnected: (data) => dispatch(setDisconnected(data)),
	saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Libary)
