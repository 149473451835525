export const getNetworkById = async (chainId) => {
	if (chainId == '1') {
		return NetworksTypes.EthereumMainNet
	} else if (chainId == '5') {
		return NetworksTypes.EthereumTestNet
	} else if (chainId == '56') {
		return NetworksTypes.BinanceMainNet
	} else if (chainId == '97') {
		return NetworksTypes.BinanceTestNet
	} else if (chainId == '137') {
		return NetworksTypes.PolygonMainNet
	} else if (chainId == '80001') {
		return NetworksTypes.PolygonTestNet
	} else {
		return false
	}
}

export const NetworksTypes = Object.freeze({
	EthereumMainNet: {
		title: 'Ethereum Mainnet',
		chainId: '1',
		icon: '/images/ethereum.png',
		mainnet: true,
		symbol: 'ETH',
		networkUrl: '',
		tokenUrl: '',
		swapUrl: 'https://app.uniswap.org/#/swap?outputCurrency=',
		exchange: 'UniSwap',
		rpcProvider: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
	},
	EthereumTestNet: {
		// title: 'Ethereum Mainnet',
		title: 'Ethereum Testnet Goerli',
		chainId: '5',
		icon: '/images/ethereum.png',
		mainnet: false,
		symbol: 'ETH',
		networkUrl: 'https://goerli.etherscan.io/address',
		tokenUrl: 'https://goerli.etherscan.io/token',
		swapUrl: 'https://app.uniswap.org/#/swap?outputCurrency=',
		exchange: 'UniSwap',
		rpcProvider: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
	},
	BinanceMainNet: {
		title: 'Binance Smart Chain Mainnet',
		chainId: '56',
		icon: '/images/binance.png',
		mainnet: true,
		symbol: 'BNB',
		networkUrl: 'https://bscscan.com/address',
		tokenUrl: 'https://bscscan.com/token',
		swapUrl: 'https://pancakeswap.finance/swap?outputCurrency=',
		exchange: 'PancakeSwap',
		rpcProvider: 'https://bsc-dataseed.binance.org'
	},
	BinanceTestNet: {
		// title: 'Binance Smart Chain Mainnet',
		title: 'Binance Smart Chain Testnet',
		chainId: '97',
		icon: '/images/binance.png',
		mainnet: false,
		symbol: 'BNB',
		networkUrl: 'https://testnet.bscscan.com/address',
		tokenUrl: 'https://testnet.bscscan.com/token',
		swapUrl: 'https://pancake.kiemtienonline360.com/#/swap?outputCurrency=',
		exchange: 'PancakeSwap',
		rpcProvider: 'https://data-seed-prebsc-1-s1.binance.org:8545'
	},
	PolygonMainNet: {
		title: 'Polygon Mainnet',
		chainId: '137',
		icon: '/images/polygon.png',
		mainnet: true,
		symbol: 'Matic',
		networkUrl: '',
		tokenUrl: '',
		swapUrl: 'https://quickswap.exchange/#/swap?outputCurrency=',
		exchange: 'QuickSwap',
		rpcProvider: 'https://polygon-rpc.com'
	},
	PolygonTestNet: {
		// title: 'Polygon Mainnet',
		title: 'Polygon Testnet Mumbai',
		chainId: '80001',
		icon: '/images/polygon.png',
		mainnet: false,
		symbol: 'Matic',
		networkUrl: 'https://mumbai.polygonscan.com/address',
		tokenUrl: 'https://mumbai.polygonscan.com/token',
		swapUrl: 'https://quickswap.exchange/#/swap?outputCurrency=',
		exchange: 'QuickSwap',
		rpcProvider: 'https://rpc-mumbai.maticvigil.com'
	}
})

export const packegeSeeder = [
	{
		title: 'Packege 1',
		point: 10,
		price: 0.9
	},
	{
		title: 'Packege 2',
		point: 20,
		price: 1.2
	},
	{
		title: 'Packege 3',
		point: 50,
		price: 2
	}
]
