import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import { useSelector } from 'react-redux'
import { saveUser } from '../actions/authActions'
import { fetchUserSSSTokensBalance } from '../hooks/contracts'
import { connect } from 'react-redux'
import { Spacer } from '../styles/shared'
import { getUserProfile } from '../api'
import { MDBDataTableV5 } from 'mdbreact'

const Profile = ({ saveUserHandler }) => {
	let user = useSelector((state) => state.auth.user)
	const [profileImage, setProfileImage] = useState(null)
	const tokenContract = useSelector((state) => state.auth.tokenContract)
	const isConnected = useSelector((state) => state.auth.isConnected)
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	const [userDetails, setUserDetails] = useState(user?.username)

	const [buyList, setBuyList] = useState({
		columns: [
			{ label: 'address', field: 'address', width: 470, sort: String('disabled') },
			{ label: 'Title', field: 'title', width: 470, sort: String('disabled') },
			{ label: 'Points', field: 'point', width: 470, sort: String('disabled') },
			{ label: 'Price', field: 'price', width: 470, sort: String('disabled') },
			{ label: 'Purchase/Completion date', field: 'date', width: 150, sort: String('disabled') }
		],
		rows: []
	})

	useEffect(() => {
		viewAllPaymentHistoryList()
		setUserDetails(user?.username)
	}, [isConnected, user])

	//show please wait for transaction on all buttons

	const refreshSSSTokens = async () => {
		if (user) {
			const sssTokens = await fetchUserSSSTokensBalance(user.token, tokenContract)
			user.sssTokens = parseFloat(sssTokens).toFixed(2)
			saveUserHandler(user)
		}
	}

	const uploadProfile = ({ target }) => {
		const reader = new FileReader()
		reader.readAsDataURL(target.files[0])

		reader.onload = () => {
			if (reader.readyState === 2) {
				setProfileImage(reader.result)
			}
		}
	}

	const DeleteProfileImage = () => {
		setProfileImage(null)
	}

	const buySSST = async () => {
		window.open('https://pancake.kiemtienonline360.com/#/swap')
	}

	const viewAllPaymentHistoryList = async () => {
		if (isConnected) {
			const _token = sessionStorage.getItem('auth_token')
			if (_token) {
				let response = await getUserProfile()
				setBuyList((preValue) => {
					return {
						columns: [...preValue.columns],
						rows: []
					}
				})
				if (response) {
					setUserDetails(response?.data)
					if (response?.data?.payment_history?.length) {
						response?.data?.payment_history?.map((re) => {
							createBuyHistoryTable(response?.data?.address, re)
						})
					}
				}
			}

		} else {
			setBuyList((preValue) => {
				return {
					columns: [...preValue.columns],
					rows: []
				}
			})
		}

	}

	const createBuyHistoryTable = (address, row) => {
		let _obj = {
			address: address,
			title: row.package_name,
			point: row.points,
			price: <div style={{ display: 'flex', justifyContent: 'center' }}>{row.price > 0 ? row.price + ' ETH' : '-'}</div>,
			date: row?.date
		}
		setBuyList((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	return (
		<Wrapper>
			<CustomRow id="eee">
				<Col lg={12}>
					<Form isDarkTheme={isDarkTheme}>
						{/* <Row>
							<Col lg={12}>
								<Heading isDarkTheme={isDarkTheme}>Your Profile</Heading>
								<Spacer height={1} />
							</Col>
						</Row> */}
						<ContentWrapper>

						</ContentWrapper>
						<ContentWrapper>
							<div>Coins:</div>
							<div>{userDetails?.coins}</div>
							<div>Points:</div>
							<div>{userDetails?.total_points}</div>
						</ContentWrapper>
					</Form>
				</Col>
			</CustomRow>
			<CustomRow>
				<Col lg={12}>
					<Container>
						<Form isDarkTheme={isDarkTheme}>
							<Col lg={12}>
								<Heading isDarkTheme={isDarkTheme}>Points History</Heading>
								<Spacer height={1} />
								<HistoryTable
									responsive={true}
									info={false}
									btn={true}
									theadTextWhite={isDarkTheme}
									tbodyTextWhite={isDarkTheme}
									noBottomColumns={true}
									hover={!isDarkTheme}
									paging={false}
									data={buyList}
									searchTop={false}
									searchBottom={false}
								/>
							</Col>
						</Form>
					</Container>
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const ContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	gap: 1rem;
`
const HistoryTable = styled(MDBDataTableV5)`
	@media screen and (min-width: 410px) and (max-width: 439px) {
		width: 22rem;
	}
	@media screen and (min-width: 440px) and (max-width: 499px) {
		width: 24rem;
	}
`

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	@media screen and (min-width: 420px) and (max-width: 500px) {
		width: 100%;
	}
	${media.sm`
        width: 100%;
    `};
	${media.md`
        width: 100%;
    `};
	${media.lg`
        width: 100%;
    `};
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const Form = styled(Container)`
	padding: 1rem;
	${media.xs`
	padding:  1rem 0rem;
	`}
`

const Heading = styled.h2`
	line-height: 1.85rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.aqua_blue : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 1rem;
	font-weight: bold;
`

const mapStateToProps = (state) => {
	return {
		// user: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => ({
	saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
