import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import { useSelector } from 'react-redux'
import { Flexed, Spacer, Text } from '../styles/shared'

const InfoContent = () => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)

	const [ssstDetails, setSSSTDetails] = useState([
		{
			id: 1,
			notch: '/images/mini_pink_notch.svg',
			backGround: 'pink_mirror',
			name: 'Staking',
			iconSection: '/images/icon_pink_section.png',
			icon: '/images/stack.png',
			description: 'Users can stake their SSST tokens to earn SSST rewards, as well as special NFTs. Users can earn additional rewards by staking their character NFTs, allowing them to be used as AI enemies in custom campaigns.'
		},
		{
			id: 2,
			notch: '/images/mini_blue_notch.svg',
			backGround: 'blue_mirror',
			name: 'Play & Earn',
			iconSection: '/images/icon_blue_section.png',
			icon: '/images/earn.png',
			description: 'Users can stake their SSST tokens to earn $COA rewards, as well as special NFTs. Users can earn additional rewards by staking their character NFTs, allowing them to be used as AI enemies in custom campaigns.'
		},
		{
			id: 3,
			notch: '/images/mini_orange_notch.svg',
			backGround: 'orange_mirror',
			name: 'In Game Marketplace',
			iconSection: '/images/icon_orange_section.png',
			icon: '/images/swap.png',
			description: 'Buy and sell character and equipment NFTs directly from the Chain of Alliance user interface, bypassing secondary marketplaces.'
		}
	])

	return (
		<>
			<Spacer height="2" />
			<Wrapper>
				{ssstDetails.map((info, index) => {
					return (
						<Card key={index + 2}>
							<NotchImg src={info?.notch} alt="notch" />
							<CardBody backGround={info?.backGround}>
								<Spacer height={4} />
								<CardHeading isDarkTheme={isDarkTheme}>{info?.name}</CardHeading>
								<Spacer height={1} />
								<Flexed direction="row" justify="center" align="center">
									<IconDisplay src={info?.iconSection} />
									<Image isDarkTheme={isDarkTheme} src={info?.icon} />
								</Flexed>
								<Spacer height={1.5} />
								<Text xsmall isDarkTheme={isDarkTheme} isCentered color={isDarkTheme ? 'description_Text' : 'off_dark'}>
									{info?.description}
								</Text>
								<Spacer height={4} />
							</CardBody>
							<NotchImgBottom src={info?.notch} alt="bottom-notch" />
						</Card>
					)
				})}
			</Wrapper>
			{/* <Wrapper>
				<CardPre>
					<CardBodyPre>
						<HeadingPre isDarkTheme={isDarkTheme}>Staking</HeadingPre>
						<Spacer height="1.5" />
						<TextPre isDarkTheme={isDarkTheme}>
							Users can stake their SSST tokens to earn SSST rewards, as well as special NFTs. Users can earn additional rewards by staking their character NFTs, allowing them to be used as AI enemies in custom campaigns.
						</TextPre>
					</CardBodyPre>
				</CardPre>

				<CardPre>
					<CardBodyPre>
						<HeadingPre isDarkTheme={isDarkTheme}>Play & Earn</HeadingPre>
						<Spacer height="1.5" />
						<TextPre isDarkTheme={isDarkTheme}>
							Users can stake their SSST tokens to earn $COA rewards, as well as special NFTs. Users can earn additional rewards by staking their character NFTs, allowing them to be used as AI enemies in custom campaigns.
						</TextPre>
					</CardBodyPre>
				</CardPre>

				<CardPre>
					<CardBodyPre>
						<HeadingPre isDarkTheme={isDarkTheme}>In Game Marketplace</HeadingPre>
						<Spacer height="1.5" />
						<TextPre isDarkTheme={isDarkTheme}>Buy and sell character and equipment NFTs directly from the Chain of Alliance user interface, bypassing secondary marketplaces.</TextPre>
					</CardBodyPre>
				</CardPre>

				<CardPre>
					<CardBodyPre>
						<HeadingPre isDarkTheme={isDarkTheme}>NFTs</HeadingPre>
						<Spacer height="1.5" />
						<TextPre isDarkTheme={isDarkTheme}>
							Utilizing the Chromia Originals NFT Standard, all characters and equipables will be represented as NFTs and their corresponding metadata will be stored on chain. This allows the progress of the character to be stored on
							the blockchain and traded along with it.
						</TextPre>
					</CardBodyPre>
				</CardPre>
			</Wrapper> */}

			<Spacer height="2" />
		</>
	)
}

const Wrapper = styled(Container)`
	display: block;
	text-align: center;
	max-width: 100%;
	padding-top: 1.2rem;
	padding-bottom: 2rem;
`
const CardPre = styled.div`
	display: inline-block;
	position: relative;
	margin: 0 2rem 1.8rem 2rem;
	${media.xs`
		margin: 0 0rem 1.8rem 0rem;
    `};
	${media.sm`
		margin: 0 2rem 1.8rem 2rem;
    `};
	@media screen and (min-width: 849px) and (max-width: 991px) {
		margin: 0 1rem 1.8rem 1rem;
	}
	@media screen and (min-width: 1278px) and (max-width: 1472px) {
		margin: 0 1rem 1.8rem 1rem;
	}
`

const CardBodyPre = styled.div`
	color: #161a2f;
	display: inline-block;
	vertical-align: top;
	min-height: 380px;
	padding: 40px 20px;
	position: relative;
	text-align: center;
	width: 26rem;
	cursor: pointer;
	background: linear-gradient(135deg, #76f0e7 0%, #9883eb 100%);
	opacity: 0.5;
	&:hover > h1 {
		top: 0rem;
		transition: 0.3s all ease;
	}
	&:hover > h1:before {
		width: 85% !important;
		transition: 0.3s all ease;
	}
	&:hover {
		opacity: 1;
		transition: 0.4s all ease;
	}
	&:hover > p {
		display: block !important;
		transition: 0.4s all ease;
	}
	// margin-bottom:2rem;
	@media screen and (min-width: 0px) and (max-width: 369px) {
		width: 19rem;
	}
	@media screen and (min-width: 370px) and (max-width: 399px) {
		width: 21.5rem;
	}
	@media screen and (min-width: 400px) and (max-width: 450px) {
		width: 23rem;
	}
	@media screen and (min-width: 1278px) and (max-width: 1472px) {
		width: 23.5rem;
	}
	@media screen and (min-width: 849px) and (max-width: 991px) {
		width: 23.5rem;
	}
`

const HeadingPre = styled.h1`
	font-family: 'bicubik', sans-serif;
	position: relative;
	letter-spacing: 0.1em;
	font-size: ${({ FontSize }) => (FontSize ? `${FontSize}px` : '25px')};
	font-weight: bold;
	display: inline-block;
	color: #161a2f;
	text-transform: capitalize;
	min-width: ${({ width }) => `${width}`};
	margin: ${({ margin }) => `${margin}`};
	text-align: ${({ isCentered }) => (isCentered ? `center` : 'inherit')};
	top: 7rem;
	transition: 0.3s all ease;
	&:before {
		content: '';
		position: absolute;
		width: 0;
		height: 2px;
		background: #5af2e8;
		left: 0;
		right: 0;
		margin: auto;
		bottom: -12px;
		transition: 0.3s all ease;
	}
`

const TextPre = styled.p`
	font-family: 'Montserrat-Regular', sans-serif;
	letter-spacing: 0.1em;
	font-size: ${({ FontSize }) => (FontSize ? `${FontSize}px` : '16px')};
	display: inline-block;
	text-transform: normal;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black2}` : `${palette.black2}`)};
	display: none;
	transition: 0.3s all ease;
	font-weight: bold;
`

const Card = styled.div`
	display: inline-block;
	position: relative;
	z-index: 2;
	margin: 0 2rem 1.8rem 2rem;
	${media.xs`
	margin: 0 0rem 1.8rem 0rem;
`};
	${media.sm`
	margin: 0 2rem 1.8rem 2rem;
`};
	transition: all 0.4s ease-in-out;
	&:hover {
		transform: scale(1.03);
	}
	@media screen and (min-width: 849px) and (max-width: 991px) {
		margin: 0 1rem 1.8rem 1rem;
	}
	@media screen and (min-width: 1278px) and (max-width: 1472px) {
		margin: 0 1rem 1.8rem 1rem;
	}
`

const CardBody = styled.div`
	clip-path: polygon(0 0, 12% 0, 28% 7%, 71% 7%, 86% 0, 100% 0, 100% 100%, 88% 100%, 70% 93%, 29% 93%, 14% 100%, 0 100%);
	padding: 0rem 0.2rem;
	display: inline-block;
	vertical-align: top;
	text-align: left;
	overflow: hidden;
	position: relative;
	background: ${({ backGround }) => palette[backGround]};
	border: none;
	font-size: 1rem;
	width: 20rem;
	min-height: 22rem;
	transition: all 0.4s ease-in-out;
	&:hover #video {
		display: block;
	}
	&:hover #image {
		display: none;
	}
`
const NotchImg = styled.img`
	position: absolute;
	top: -16px;
	width: ${({ width }) => (width ? width : '106.2%')};
	z-index: 2;
	left: -7px;
`

const NotchImgBottom = styled.img`
	position: absolute;
	bottom: -16px;
	width: ${({ width }) => (width ? width : '106.2%')};
	z-index: 2;
	left: -7px;
	transform: rotate(180deg);
`

const Image = styled.div`
	position: absolute;
	background: ${({ src, isDarkTheme }) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	background-size: contain;
	background-position: center;
	width: 2.3rem;
	height: 2.3rem;
`

const IconDisplay = styled.img`
	width: 13rem;
	z-index: 4;
	position: relative;
`

const CardHeading = styled.div`
	font-weight: bold;
	text-align: center;
	letter-spacing: 0.1em;
	font-size: 1rem;
	font-family: 'bicubik';
	z-index: 7;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
`
export default InfoContent
