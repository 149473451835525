export const LibraryData = [
	{
		normalHeading: ['Market Overview in Brief'],
		paragraph: [
			'The web3 market is expected to increase significantly and to hit a peak of 83BN USD until 2030. The web3 gaming market size is currently estimated to be 5 bn USD and is expected to reach 37 bn USD by 2032. There are 2.47 million active wallets per day. This number is expected to increase until 2030.',
			'Increased transparency, scalability and data security could be a reason for the significant increase. Also regulators  are  working  together  with  financial  institutions,  governments  and  fintech  companies  to provide a transparent and regulated platform for investing or trading digital assets in web3.',
			'GameFi and so called Play-to-Earn (P2E) are parts of the larger trend of web3 games, which is known the intersection of gaming and finance.',
			'Three  different  types  of  architectures  are  nowadays  widely  used  and  known  as  the  most  popular network architectres: 1. Centralized architecture 2. Decentralized architecture 3. Distributed architecture'
		]
	},
	{
		normalHeading: ['Web3.0'],
		paragraph: [
			'Web3.0 is the internet of value or the internet of ownership. Different than web1.0, which was purely the internet of information, or web2.0, which was the  internet of  interaction, It offers ways to read information, publish created content, own digital content, and execute digital agreements. Blockchain is one technology that enables the idea of ownership and value, among others, e.g., private DLT nodes. Web3  has  many  layers,  or  areas.  Blockchain  is  one  area,  other  areas  of  web3.0  are  in  brief:  digital assets, smart contracts, sidechains, API connection, wallets, or dApps.'
		]
	},
	{
		normalHeading: ['Decentralized architectures'],
		paragraph: [
			'This architecture infrastructure is not centric, it is distributed to multiple main nodes or servers. Several main nodes exist. Every main node in the network is connected with at least one another main node, where  each  of  those  nodes  contains  the  same  copy  of  data  available  and  must  provide  the  same services  as  other  nodes.  Decentralized  architectures  are  a form  of  a  distributed  ledger  technology (DLT). Blockchain is a decentralized architecture.',
			'The decentralized system is less likely to be unavailable for users  compared to a centralized system, but  has  also  some  disadvantages,  e.g.,  higher  maintenance  costs  or  security  and  privacy  concerns, because the data is available at multiple locations. Also, this technology solution should be properly optimized otherwise it may lead to inconsistent performance.'
		]
	},
	{
		normalHeading: ['Distributed architectures'],
		paragraph: [
			'A distributed system is the same as a decentralized system, no central owners exist and users have the same level of data access. However, privileges can be limited to specific users, if required. This solution is highly fault tolerant and is transparent, which makes it more secure compared to other solutions. However, it has high maintenance costs and is difficult to deploy. Blockchain is a form of a distributed architecture, however other distributed ledger technologies exist as well.'
		]
	},
	{
		normalHeading: ['Centralized architectures'],
		paragraph: [
			'Two types of nodes exist, that participate in the centralized system. The first one is the server or main node and the second one is the client or user node. The main node is the heart of the network. The centralized architecture is cheaper and simpler to develop than a decentralized architecture, but has also some disadvantages, e.g., it has Higher security and privacy risks for users and requirea s long time for accessing the data for users who are physically not close to the server. Also in the case of a server downtime, some data might be lost, when there is no parallel node running during the downtime.'
		]
	},
	{
		normalHeading: ['“The DAO” and the DAO hack'],
		paragraph: [
			'DAO has been created in the Ethereum network with the purpose of serving as a decentralised and investor-directed venture capital fund to allow investors to vote on projects they might invest in. DAO is considered as an Ethereum-based venture capital fund that was supposed to run based on smart contracts without a conventional management structure.',
			'However, DAO failed after users exploited a vulnerability in the code of the application and hacked it in June 2016, resulting in the diversion of about 1/3 of the committed funds by the hackers.',
			'In addition, about one month later, the US Securities and Exchange Commission (SEC) found “the DAO” to be in violation of US securities law, deeming the DAO tokens to be securities.'
		]
	},
	{
		normalHeading: ['Dash (DASH)'],
		paragraph: [
			'DASH is a cryptocurrency based on DAO. The Dash peer-to-peer network was founded in 2014 to offer an agile version of Bitcoin in terms of transaction speed and governance. ',
			'In a DASH mixing process, the origin of funds in a DASH transaction is obfuscated (jumbled) by breaking down the transaction amount into smaller denominations of DASH that are then matched to other user transactions. By mixing different denominations, the origin of funds can no longer be determined.'
		]
	},
	{
		normalHeading: ['VeChain'],
		paragraph: [
			'VeChain is called VET and was created in 2015.',
			'VeChain is a blockchain platform with the mission of streamlining supply chain management with distributed ledger technology (DIT). VeChain is created to optimize the flow of supply chains and to enhance business processes in a secure distributed ledger technology.',
			'VeChain strives for “mass business adoption”, and believes their technology hast he potential to be the future standard for supply chain management.',
			'One of the VeChain tokens is VET, which is used to settle smart contracts across the VeChain network.'
		]
	},
	{
		normalHeading: ['The metaverse '],
		paragraph: [
			'The metaverse comprises multiple emerging technologies, and organizations should be cautious when investing in a specific metaverse because yet it is too early to determine which investments are viable for businesses in the long term.',
			'Metaverse technologies have the potential to the next level of interaction in the virtual and physical worlds, offering innovative new opportunities and business models. In fact, it may be possible for users to spend time in the metaverse for shopping, education, social media and/or entertainment. In general, the metaverse can be understood as the 3D version of the internet, which enables the users to be inside their favorite series, shopping venue, vacation place or game and so forth. All in all, a metaverse is a collective virtual shared space, created by the convergence of virtually enhanced physical and digital reality. It is also possible to learn driving or learn doing a surgery as a part of an education program. Companies and industries are interested in writing use cases to optimally use the metaverse, and also luxury brands think of offering their products in the metaverse. it is the place, where avatars of each individual and of celebrities are created and animation videos or 3D games are programmed.'
		]
	},
	{
		normalHeading: ['Type of Blockchains']
	},
	{
		normalHeading: ['Public Blockchain'],
		paragraph: ['A public Blockchain is permissionless and accessible to public.']
	},
	{
		normalHeading: ['Private Blockchain'],
		paragraph: [
			'A private Blockchain is permissioned and has known identities. It is accessible to single organisations only. The private Blockchain is lighter and faster than the public Blockchain. However, it has a centralized network character.'
		]
	},
	{
		normalHeading: ['Federated or Consortium'],
		paragraph: ['The federated or consortium Blockchain is permissioned and has known identities. It is accessible to multiple selected organisations and lighter and faster than the public Blockchain. ']
	},
	{
		normalHeading: ['Fork'],
		paragraph: [
			'When a blockchain splits into two, it is called a fork. Several types of so called forks does exist, the most known is the hard fork and soft fork. Both are importand for a continued functioning of the blockchain. A protocol change which requires all network nodes to upgrade their software to the latest version to keep participating in the network is called „hard fork“. In this case, the nodes of the new blockchain do not anymore accept the rules of the old blockchain. So the ruleset is replaced by the new rules. In the case of a hard fork, basically two blockchains exist side by side. A new currency can exist as a result of the hard fork. A famous example for a hard fork is Bitcoin Cash (BCH).',
			'A soft fork is a change to the blockchain ́s software protocol where only previously valid transaction blocks are made invalid. The difference is basically that, in a soft fork only a majority of the miners require an upgrade to enforce the new rules, whereas in a hard fork all nodes must to upgrade and agree on the new version '
		]
	},
	{
		normalHeading: ['Tether (USDT)'],
		paragraph: [
			'Tether is a stablecoin, also known as USDT and aims to provide a less volatile digital currency by pegging its value to the US dollar at a 1:1 ratio. USDT is one of the most widely adopted stablecoins, which are blockchain-based currencies that are tied - or tethered - to fiat currencies.',
			'Tether’s mission is to offer a safer digital asset with less volatility.',
			'Tether faces competition from other stablecoins like USDC, which is also pegged to the US dollar.',
			'Tether as a company currently offers four stablecoins tied to their respective fiat currencies, including USDT, EURT, CNHT, and MXNT.'
		]
	},
	{
		normalHeading: ['Stablecoin'],
		paragraph: [
			'Stablecoins are cryptocurrencies whose value is pegged, or tied, to that of another currency, commodity, or financial instrument. Stablecoins aim to provide an alternative to the high volatility of the most popular cryptocurrencies, including Bitcoin (BTC), which has made crypto investments less suitable for common transactions.'
		]
	},
	{
		normalHeading: ['Stellar (also known as XLM)'],
		paragraph: [
			'Basically, Stellar is an open-source blockchain network for developers to create interoperable financial applications and for end users to spend digital money andnjoy fast, efficient trading. ',
			'Stellar is designed to connect and digitise financial systems and currencies from all over the world on one open source blockchain network.',
			'Founded by Jed McCaleb, the creator of eDonkey, Mtgox.com and the Ripple protocol, the Stellar ecosystem handles millions of transactions a day at high speed and low cost. ',
			'The native asset of the Stellar network is called lumen (XLM) and is used for transactions and initialising accounts.'
		]
	},
	{
		normalHeading: ['B-Money'],
		paragraph: [
			'B-Money is an early-age distributed cash system, which is developed by Wei Dai, who also co-proposed the  so  called  VMAC  message  authentication  algorithm.  B-money  was  proposed  by  Wei  Dai  as “Anonymous, Distributed Electronic Cash System” in his white paper in November 1998.',
			'B-money is introduced to create a financial system over the internet with standard principles similar to modern cryptocurrency. With the aim to move money freely via the decentralized network without involving any third party.'
		]
	},
	{
		normalHeading: ['Bitgold'],
		paragraph: [
			'Bitgold is a well known decentralized digital currency project, which was taken before Blockchain. It was developed by Nicholas (Nick) Szabo in 1998. The Bitcoin and Bitgold protocols are similar. They both run on a Byzantine Fault Tolerant (BFT) consensus, in a peer-to-peer decentralized network.'
		]
	},
	{
		normalHeading: ['Multi-Signature Wallets'],
		paragraph: ['Whereas a traditional cryptocurrency wallet has only one private key, Multisig wallets require at minimum two private keys to sign a transaction. The purpose is to increase the wallet ́s security.']
	},
	{
		normalHeading: ['What is a transaction fee and why are they requested? '],
		paragraph: ['In general, fees are required, to include a transaction into the next block.', 'The fees depend on the network that is used to generate a transaction, it can vary or be at a fixed price, e.g., in the case of Bitcoin.']
	},
	{
		normalHeading: ['What is a public key, a private key and a wallet address?'],
		paragraph: [
			'In cryptography, at least one public key and one private key are needed to access an encrypted information. Crypthography has the purpose to keep certain information secret. A Public key is comparable to account information and is usually seen by the receiver. So, it can be shared with everyone, and anyone can potentially send transactions to this address. However, a private key is kept private and should be secured properly. It hast he same purpose as a PIN code.',
			'A wallet address is a set of numbers and letters, with up to 46 alpha-numeric characters. In a wallet, the addresses of digital assets, e.g., tokens or cryptocurrencies are stored, which are purchased. For instance, the ethereum wallet is MEW. A wallet can be downloaded in the app store or it can be purchased in an online store, for isntance an external hardware wallet. Every blockchain has a different wallet, for instance, with the MEW wallet, you can only purchase and store ethereum tokens, cryptocurrencies and digital assets. However, wallets exist from decentralized exchanges where you can store different digital asset types of different blockchain networks. An external hardware wallet is recommended to be used to store your keys (the addresses of your digital assets).'
		]
	},
	{
		normalHeading: ['how can I keep my cryptocurrencies safe?'],
		paragraph: [
			'Acitvate 2-factor authentication, wherever it is possible',
			'Be cautios and use encrypted password manager ',
			'Choose a trustworthy platform to buy or sell your cryptocurrencies and digital assets ',
			'Be up to date with cyber risks and keep your software up to date',
			'Store passwords in a safe place, where only you can access the information. Do not use your mobile phone nor laptop for saving the passwords of your wallets etc.'
		]
	},
	{
		normalHeading: ['Solidity'],
		paragraph: ['Solidity is an object oriented programming language, which is used for writing smart contracts, especially in Ethereum. Furthermore, Solidity is influenced by JavaScript, Python and C++.']
	},
	{
		MainHeading: 'Industry'
	},
	{
		normalHeading: ['Blockchain in Financial Services'],
		paragraph: [
			'According to a recent study the total amount of data created, captured, copied, and consumed globally is increasing rapidly. In 2020 it was reaching 64.2 zettabytes. In the upcoming years, it is expected to grow more than 180 zettabytes by the end of 2025. Most of the data is assumed to be financial data. Also according to a study, up to 80% of the financial data, which is available in the internet and in the systems, remains unused and unmanaged. A high demand exist to be aware of the data and to manage it properly, in particular to protect data from access of 3rd parties. Especially when it comes to fraud or cyber attacks. ',
			'Blockchain  promises  to  reduce  fraud,  ensure  automated,  paperless,  and  secure  transactions  and trades,  and  finally  help  to  manage  risks  within  the  interconnected  financial  systems.  Furthermore, Blockchain  is  able  to  increase  transparency,  and  at  the  same  time  is  less  susceptible  to  fraud  and cheaper for consumers, because it can reduce intermediaries and manual process steps.'
		]
	},
	{
		normalHeading: ['Blockchain in Health Care'],
		paragraph: [
			'According  to  a  recent  published  article  Blockchain  has  the  potential  to  transform  the  health  care industry,  e.g.,  placing  the  patient  at  the  center  of  the  health  care  ecosystem  and  increasing  the security,  privacy,  and  interoperability  of  health  data.  Blockchain  is  able  to  provide  a  new  model  for health information exchanges (HIE) by making electronic medical records more efficient, disintermediated, and secure. While it is not a panacea, this new, rapidly evolving field provides fertile ground for experimentation, investment, and proof-of-concept testing'
		]
	},
	{
		normalHeading: ['Blockchain in Fashion and Arts']
	},
	{
		normalHeading: ['NFT, Fashion and Metaverse'],
		paragraph: [
			'NFTs and the Metaverse opens doors to new artists and transforms the world of fashion. NFTs play a high role in the Fashion industry. Art is created virtually and placed in forums like openSea to sell them to the hihest bidder. Additionally, NFTs and avatars of famous artists or models are created in the metaverse. a new industry „the virtual fashion“ industry is about to be born.'
		]
	},
	{
		normalHeading: ['Blockchain in Gaming '],
		paragraph: [
			'Since 2017, blockchain games exist. Existing video games include elements of blockchain technologies, including cryptocurrencies and non-fungible tokens (NFTs),  allowing players to buy, sell, or trade in-game items with each other. The game publisher takes a so called gas fee for transactions and subset of these games are known as play-to-earn games.'
		]
	},
	{
		MainHeading: 'Main challenges'
	},
	{
		paragraph: [
			'Success stories in the market exist in terms of adopting blockchain technology in different industries. For instance, in the pharma industry, companies have adopted blockchain to secure supply chains for medicine and confidential test data. Another example is Walmart, who implemented blockchain to cut product tracing times and was able to reduce the tracing times from seven days to 2.2 seconds. Also the Ethiopian Ministry of Education considered to adopt the distributed ledger technology to create blockchain-based digital IDs for five million students. However, challenges exist as well. One of the main callenges is stated below.'
		]
	},
	{
		normalHeading: ['Security and trust'],
		paragraph: [
			'Many blockchain users do not trust the network nor each other.',
			'This challenge is twofold. Organizations may not trust the security of the technology itself, and they may  not  trust  other  parties  on  a  blockchain  network.  One  oft  he  reasons  is,  that  some  consensus mechanisms have a higher security either because they are private or have delegated an authority to validate the transaction in the chain, but they loose in other factors such as cost or scalability.',
			'All in all, transactions in the  blockchain are believed to be secure, private and verified. Even though there  is  no  central  authority  present  to  validate  and  verify  the  transaction  in  the  decentralized network.  The  key  part  of  any  blockchain  network  is  the  consensus  algorithms  which  drive  common agreement  about  the  present  state  of the  distributed  ledger  for  the entire  network.  It  ensures  that every new block added is the one and only version of the truth agreed upon by all the nodes  in the blockchain. In general, businesses have found trust in private blockchains where there are no unknown users.  To  build  trust  among  users,  platforms  such  as  TradeLens  (which  is  a  global  logistics  network created  by  Maersk  and  IBM)  show  what can  happen  when  peers  and competitors work together  to develop  solutions  to  common  challenges.  Unlike  anonymous  public  blockchains,  on  the  TradeLens private  blockchain,  members  are  called  "Trust  Anchors" and  known  to  the  network  based  on cryptographic identities. TradeLens uses a permissioned blockchain to offer immutability, privacy and traceability of shipping documents, which is believed to create trust and security.'
		]
	},
	{
		normalHeading: ['Scalability'],
		paragraph: [
			'Scalability is important to increase the number of transactions in the chain and to provide a time to market solution to companies, which is highly automated in increased in speed, to save time and costs. However, not every consensus algorithm is able to provide high scalability, and those who are highly scalable, may not be suitable for certain company structure because of other factors, that also matters. Some of the highly scalable algorithms are the delegated proof of stake or the delegated byantine fault tolerance.'
		]
	},
	{
		normalHeading: ['Feasibility'],
		paragraph: [
			'Feasibility includes all the main challenges and benefits of a blockchain solution. Cost is a key factor to decide on the right technology solution or the consensus mechanism of blockchain, also whether to implement a blockchain or not. For instance, a public blockchain is less cost intensive than a private blockchain. However, a private blockchain is more secure against cyber attacks and has a higher scalability than a public blockchain. The proof of capacity (also called proof of space) algorithm seems to be cost effective, because it requires less energy to run than other consensus algorithms.The benefits can differ depending on the business case and company structure, or for startups, the purpose of the startup. A feasibility study is recommended before implementing a blockchain solution.',
			'According to a published report, up to 70% of organizations who participated in a study, were familiar with blockchain in 2019, and after one year the number had grown to 80%. However, only 12% of the participanting  companies  have  reported,  that  they  went  successfully  live  with  either  blockchain  or blockchain  as  a  service  solutions.  Around  50  percent  reported,  that  they  are  in  the  early  stage  of adopting  blockchain  solutions.  So,  the  key  question  is:  What  is  holding  back  the  remaining  38%  of respondents who are not at all exploring the use of blockchain?'
		]
	},
	{
		normalHeading: ['Lack of adoption '],
		paragraph: [
			'Blockchain will require a broad adoption to function well. With lack of a widespread adoption, the effectiveness and scalability of blockchains might remain limited. However, it is expected that the adoption of blockchain technology will grow in the future.'
		]
	},
	{
		normalHeading: ['Skills gap '],
		paragraph: [
			'In  an  empirical  study  made  in  social  media,  70%  of  respondents  mentioned,  that  they  do  not understand  blockchain  well  and  find  it  to complex  to  to make  a  decision towards  blockchain  nor  to invest in blockchain products, such as cryptocurrencies or other digital assets. The skills to develp the technology  is  in  short  supply  and  many  developers  cannot  go  beyond  the  known  standard,  which makes  it almost impossible  to improve certain areas and increase the security issue  or to adopt  the technology properly in an well established corporation. However, Blockchain as a Service is believed to be the bridge to close this gap of skills.'
		]
	},
	{
		normalHeading: ['Double spending problem'],
		paragraph: [
			'The double spending problem is a main challenge in blockchain with all of the consensus mechanisms, technically it is possible to use the same coin for several transactions or to re-execute the same transaction several times. For that reason, Satoshi Nakamoto has offered in his published Bitcoin Whitepaper, to use timestamps to detect already used transactions and to prevent them from re-use.'
		]
	},
	{
		MainHeading: 'Deep dive'
	},
	{
		subHeading: ['Encryption methods', 'Hash function', 'one way (OTS) = SHA']
	},
	{
		paragraph: [
			'assymetric cryptography',
			'Examples of asymmetric cryptography system are RSA or ECC. While asymmetric encryption uses mathematical impossibilities (discrete logarithm or prime factorization computation in polynomial time), hashing functions obfuscate their working by complexification.',
			'symetric cryptography ',
			'examples of symmetric cryptography is DES. These functions seem to have a shorter lifetime than RSA, which has been used for more than 40 years.',
			'collision-resistant hash functions exist, that reduce collision resistance to the hardness of the underlying problem, but they are slow and therefore not preferred.'
		]
	},
	{
		normalHeadingLight: ["SHA (Secure Hash Algorithm) isn't encryption, it's a one-way hash function. "]
	},
	{
		paragraph: [
			'Examples of SHA encryption are:',
			'SHA 2 – this is common in financial institutions and in large corporates and until now sufficient to ensure data security.',
			'SHA 384 this is less common but has an increased security because it has a higher bit rate.',
			'SHA-384 is a truncated version of SHA-512. The output hash code for this function is 384 bit',
			'SHA-512 has 512 bit, which is 64 byte',
			'The RSA-200 challenge was broken in 2005, fifteen years ago, and only 27 years after the 1978 RSA whitepaper, which is published by Martin Gardner.',
			'The reason is, because factoring is full of mathematical relations that turn out to be helpful, and we had tremendous mathematical advances—first with the quadratic sieve, and then with the special number field sieve, and then with the general number field sieve.'
		]
	},
	{
		normalHeading: ['HashCash'],
		paragraph: [
			'HashCash is an email filter based on a proof-of-work system to identify spam mails and DoS (Denial-of-Service) attacks and is developed by Adam Back in the year 1997. A formal documentation was published in the 2002 in the pap er “HashCash - A Denial of Service Counter Measure”. ',
			'The sender calculates a hashcash stamp which is appended to the email header. This is done to make sure the sender has utilized some CPU power to calculate the stamp. Spammer is unlikely to spend resources to calculate the stamp, as the number of email increases, the computation power increases exponentially. HashCash uses a 160 bit SHA-1 encryption scheme. The PoW used by the HashCash is designed to have the first 20 bits to be zeroes thus leaving 2^140 combinations.'
		]
	},
	{
		normalHeadingLight: ['AES'],
		paragraph: [
			'AES (Advanced_Encryption_Standard) is a symmetric encryption standard, a symmetric block algorithm. AES is a format for the encryption of electronic data, and established by the U.S National Institute of Standards and Technology (NIST) in 2001. Today, AES is widely used as it is significantly stronger than DES and triple DES. Furthermore, AES is a block cipher, ist key size can be either 128, 192 or 256 bits. It is also possible to develop a 512 bit key, which might be challenging to implement. All in all, AES encrypts data in blocks of 128 bits each round, and consists of several rounds.'
		]
	},
	{
		normalHeadingLight: ['Consensus mechanisms'],
		normalHeading: ['What is consensus?'],
		paragraph: [
			'Consensus means an agreement for the network how to function correctly. Most of the nodes must agree on what is true, to reach consensus at regular intervals. One main challenge is that some nodes will  fail,  misbehave,  or  just  disagree  with  the  consensus  of  the  other  nodes  so  the  system  must  be designed in such a way that deals with this inevitability.'
		]
	},
	{
		normalHeading: ['Proof of capacity'],
		paragraph: [
			'Proof of Capacity, also called Proof of Space, allows miners to mine crypto using the empty space on their  drives.  The  main  difference  of  PoC  is  that  it  requires  less  energy  to  run,  compared  to  other consensus algorithms. However, the main critics of this consensus are the large amount of storage it requires which increases significantly with the amount of miners, data can be lost or at least it can take some time to rebuild the data of the specific node, in case a node drops out of the network and finally, it can be difficult to generate a list of hashes for the header values.'
		]
	},
	{
		normalHeading: ['Proof of Work'],
		paragraph: [
			"The PoW is well known in the Blockchain world as it is the consensus algorithm of Bitcoin and the prior algorithm  of  Ethereum.  In  this  algorithm  the  miners  will  need  to  solve  a  complex  mathematical problem  involving  cryptography.  particularly,  the  miner  take  a  block's  data  and  encrypt  it  with  a counter until they receive a valid hash. "
		]
	},
	{
		normalHeading: ['Proof of Stake'],
		paragraph: [
			'In this algorithm, the miner of the next block is chosen based on various combinations of random parameters, e.g., the amount of coin they own, how long has it been since the owner had the coin and so forth. Additionally, different instances of this algorithm are available, e.g., Proof of Deposit or Proof of Burn. ',
			'The Blockchain Ethereum is now running on the PoS consensus. The reason is, that this consensus has a higher efficiency than the PoW. One of the main challenges is, that it makes it difficult to create a single truth, because working on different chains at the same time is possible.'
		]
	},
	{
		normalHeading: ['Delegated Proof of Stake'],
		paragraph: ['This consensus algorithm is an advancement of the fundamental concepts of Proof Of Stake. The DPoS algorithm was developed by Daniel Larimer, who ist he founder of BitShares, Steemit and EOS in 2014. ']
	},
	{
		normalHeading: ['Proof of Authority'],
		paragraph: [
			"Selected individuals in the network are recognized as validators after some conditions are met, such as  identity  checks. These  validators  are  responsible  for maintaining  the  blockchain's  data.  The main challenge is that it may sacrifice the trust in this concept."
		]
	},
	{
		normalHeading: ['Byzantine Fault Tolerance consensus mechanisms in a nutschell'],
		paragraph: [
			'...The bizantyne general problem illustrates how a group of Byzantine generals have had communication problems when trying to agree on their next move...',
			'The blockchain is a distributed ledger technology and therefore may face also the byzantine general problem in the network.',
			'TByzantine  Fault  Tolerance  (BFT)  is  a  mechanism  to  reach  consensus (agreement  on  the  same  value) even when some of the nodes in the network fail to respond or respond with incorrect information.',
			'The  BFT  is  a  characteristic  of  a  system  that  can  resist  up  to  one-third  of  the  nodes  failing  or  acting maliciously. '
		]
	},
	{
		normalHeading: ['Practical Byzantine Fault Tolerance (PBFT) '],
		paragraph: [
			'The PBFT is a traditional consensus algorithm, which has been widely applied in a blockchain system to make all nodes agree to distinct transactions under the assumption that the proportion of Byzantine nodes is no more than 1/3.'
		]
	},
	{
		normalHeading: ['Simplified Byzantine Fault Tolerance (SBFT)'],
		paragraph: [
			'The validator of the block is a well known entity in this consensus. Furthermore, the validator creates and proposes a new block of transactions in the chain and a certain number of nodes must accept the block, depending on the number of faulty nodes. This consensus seems to have a better scalability than the PoW, however it has a higher centralization than other consensus mechanisms.'
		]
	},
	{
		normalHeading: ['Delegated Byzantine Fault Tolerance (DBFT) '],
		paragraph: [
			'The delegated Byzantine Fault Tolerance protocol is similar to the delegated proof of stake. Each user is able to choose a delegate in the blockchain. In particular, the users vote for and appoint delegates that represent their interests  After the delegates have been appointed, they start “voting on the truth“. ',
			'The development team of NEO, which one of the world’s largest platforms for building and deploying decentralized applications (dApps), have proposed the delegated Byzantine Fault Tolerance (dBFT) as a new protocol. '
		]
	},
	{
		normalHeading: ['Proof of Elapsed Time (POeT)'],
		paragraph: [
			"This consensus is used in the popular Hyperledger' Sawtooth project. The participants request a wait time from a secure enclave. The participant with the shortest wait time is elected as the leader, waits the amount of time it was given and proposes a block in the chain. It is positive, that the leader changes quite often, but it seems to have a security issue because it may be possible to cheat. Additionally, it has similarities to a centralized architecture. "
		]
	},
	{
		normalHeading: ['Proof of Importance (PoI)'],
		paragraph: [
			'The PoI is a consensus, which is used to determine the network participants (nodes) which are eligible to  add  a  block  to  the  blockchain.  This  process  is known by NEM as ‘harvesting’. In exchange for harvesting a block,  nodes are able to collect  the transaction fees  within that block. Accounts  with a higher importance score will have a higher probability of being chosen to harvest a block.'
		]
	},
	{
		normalHeadingLight: ['Protocols']
	},
	{
		normalHeading: ['Quorum'],
		paragraph: [
			'Quorum helps businesses in the finance sector. JP. Morgan Chase and other financial firms act as a primary financial backer of this protocol. However, Quorum remains an open-source project that is utilized by everyone. Furthermore, Quorum is associated with Ethereum since the project started by modifying the Ethereum code.'
		]
	},
	{
		normalHeading: ['Hyperledger'],
		paragraph: [
			'Hyperledger is an open-source protocol with the aim to create a suite of tools for enterprises to deploy Blockchain technologies quickly and effectively. The protocol is widely used in Blockchain software solutions because it offers libraries that can be used to speed up the development. ',
			"Hyperledger is also highly compatible with Linux. Additionally, it is designed to work effectively on the same servers that are widely used in today's business world."
		]
	},
	{
		normalHeading: ['Multichain'],
		paragraph: [
			'Multichain  aims  to  support  profit  corporations  create  private  Blockchains  for  facilitating  efficient transactions and to develop new applications for the proof-of-work systems that Blockchain technologies  rely  on.  Furthermore,  this  protocol  offers  an  API  that  can  be  used  by  Blockchain development services to streamline integration and accelerate deployment. Different than its competitors Multichain is designed to work alongside fiat currencies and physical stores of value. One of its main advantages is, that it can combine the benefits of different chains in one.'
		]
	},
	{
		normalHeading: ['Corda'],
		paragraph: [
			"Corda is a competitor of the protocol Multichain, which is designed for enterprises. Most of the offered applications have been developed for the finance and banking field. However, a wide range of custom Blockchain  solutions  can  make  use  of  Corda's  technology.  Corda  is  accredited  by  the  R3  banking consortium, which makes it is a good choice for developing Blockchains in the finance industry."
		]
	},
	{
		normalHeading: ['Enterprise Ethereum'],
		paragraph: [
			"Ethereum published a version of its software, which is particularly designed for business use cases. The main purpose of this protocol is to increase the business use cases of Blockchain software development.  With  Ethereum  Enterprise,  businesses  can  rapidly  develop  large-scale  applications  to exchange  value.  Ethereum  Enterprise  allows  businesses  to  create  proprietary  variants  of  Ethereum while  taking  full  advantage  of  the  latest  Ethereum  code.  However,  Ethereum's  license  may  make  it difficult to build proprietary variants of the software. "
		]
	},
	{
		normalHeadingLight: ['DEX']
	},
	{
		normalHeading: ['DEX stand for Decentralized exchange. '],
		paragraph: [
			'A DEX allows you to trade cryptocurrencies while owning your private key, in your private wallet, thus keeping control of your cryptos. Other advantages of a DEX include lower fees and potentially enhanced security. Ledger hardware (external) wallets can be used with many DEXes, while empowering you with the highest security for your private keys, because no one else can see your provate keys, than you. One popular example is UNISWAP.'
		]
	},
	{
		normalHeadingLight: ['DeFi in Brief']
	},
	{
		normalHeading: ['What is DeFi? '],
		paragraph: ['Defi stand for Decentralized Finance. DeFi is a term for a variety of peer-to-peer financial applications in public blockchains for cryptocurrency or financial intermediaries. ']
	},
	{
		normalHeadingLight: ['CeFi in Brief']
	},
	{
		normalHeading: ['What is CeFi?'],
		paragraph: [
			'CeFi stand for Centralized Finance and is a term for applications with the logic of a central technology solution,  with  one  administrator,  managed  user  access  rights,  in  a  private  network.  CeFi  is  the counterpart  of  DeFi  and  can  be  understood  as  the  classic  way  of  Finance.  CeFi  can  aims  to  provide cryptocurrency  investment  options  in  combination  with  several  advantages  of  DeFi  and  with  the usability and security of traditional financial instruments.'
		]
	},
	{
		normalHeadingLight: ['Merkle tree']
	},
	{
		normalHeading: ['What is a Merkle tree and what is its role in Blockchain?'],
		paragraph: [
			"Merkle trees are used in the base layer of a blockchain as a data structure for verification and storage of transactions data. A Merkle tree database is used to securely split the block's data and ensure that it  is  not  lost,  damaged,  or  altered.  This  method  of  data  management  makes  it  possible  to  validate specific transactions without downloading the entire, terabyte-sized blockchain. It is a reliable, secure, and cryptographic method of running the blockchain.",
			'Let ́s look back in the history...',
			'As a result of the fall of a Centralized Exchange (CEX) giant, many CEXs have built and implemented Merkle Trees as a form of Proof of Reserves (PoR) to keep users funds safe.'
		]
	},
	{
		normalHeading: ['Who is the Founder of Merkle Tree?'],
		paragraph: ['Ralph Merkle, a computer scientist, proposed Merkle trees in the 1987 paper "A Digital Signature Based on a Conventional Encryption Function". Cryptographic hashing was also invented by Merkle.']
	},
	{
		normalHeading: ['What Is a Merkle Tree?'],
		paragraph: [
			'A  merkle  tree  is  a  hash-based  mathematical  data  structure  that  compiles  the  summaries  of  all  the transactions in a block. It is a method to check the accuracy of data in a decentralization manner. Also, Merkle trees use hashing to store and verify the data in the blockchain. As a result of its functionalities, Merkle trees are  utilized more effectively and securely to encrypt blockchain data. Merkle trees are often  used  with  peer-to-peer  (P2P)  networks  because  of  the  need  to  have  information  shared  and validated independently.'
		]
	},
	{
		normalHeading: ['Merkle Tree Structure'],
		paragraph: [
			'In the Merkle tree, each root node contains the hash value of a data block, and non-leaf nodes contain the hash of the child nodes. The Merkle tree has a binary tree structure, with the hashes of the transactional data on the bottom row being referred to as "Leaf Nodes," the intermediate hashes are referred to as "Non-Leaf Nodes," and the hash at the top is referred to as the "Root." Even though the majority of hash tree implementations are binary (each node has two child nodes), they can also have a lot more child nodes. All the hash keys are mapped, until only one hash key remains, which is called the „hash root“.'
		]
	},
	{
		normalHeading: ['Leaf Nodes'],
		paragraph: ['Leaf Nodes are hashes of each cryptocurrency transaction in a block, also referred to as transaction IDs (TXIDs).', 'For instance, Bitcoin uses a so called Secure Hashing Algorithm 256, also known as SHA-256.']
	},
	{
		normalHeadingLight: ['Polygon MATIC '],
		paragraph: [
			'Polygon is a so called layer two, also known as “sidechain“ scaling solution. Polygon runs alongside the Ethereum blockchain and allows fast transactions and low fees. The second item, MATIC is the network’s native cryptocurrency, and used for fees, staking, and more.'
		]
	},
	{
		normalHeadingLight: ['Security'],
		paragraph: [
			'The security is one of the main challenges in Blockchain. Some consensus mechanisms, for instance, the proof of authority or the byzantine fault tolerance algorithms seem to be secure against cyber hacks, but it has a strong centralized character and a central administrator, which creates other limitations in the network.'
		]
	},
	{
		normalHeadingLight: ['Scalability'],
		paragraph: [
			"Scalability is one of the main challenges in Blockchain, especially when it comes to bussineses, where fast data processing is required constantly and in high volumes. However, highly scalable algorithms are available, which have securitiy issues, to be solved or which have other limitations. While some consensus mechanisms provide a better scalability, others don't."
		]
	},
	{
		MainHeading: ['Beyond blockchain']
	},
	{
		normalHeadingLight: ["Shor's algorithm"],
		paragraph: [
			"The Shor's algorithm is a quantum computer algorithm which is able to solve prime factors of an integer in polynomial time. This algorithm is developed in 1994 by an american mathematician Peter Shor. It is believed to be much  faster than the known most efficient known  traditional factoring algorithms. Currently quantum computers are not in use, as they are not sufficiently developed yet. But in theory, if  a  quantum  computer  with  a  sufficient  number  of  qubits  could  operate  without  succumbing  to quantum noise and other quantum-decoherence phenomena, then the Shor's algorithm could be able to  break  public-key  cryptography  schemes,  such  as  The  RSA  scheme  (which  is  used  in  Bitcoin Blockchain),  the  Finite  Field  Diffie-Hellman  key  exchange,  or  the  Elliptic  Curve  Diffie-Hellman  key exchange (which is used in Bitcoin Blockchain). "
		]
	},
	{
		normalHeadingLight: ['GSSAPI'],
		paragraph: [
			'The so called „Generic Security Service Application Program Interface“ also known as GSSAPI, or GSS-API, is an application programming interface for different programs to access security services. The GSSAPI is an IETF (internet engineering task force) standard that addresses the problem of many similar but incompatible security services in use as of 2005. It is worth mentioning, that the GSSAPI itself does not provide any security. It ist he other way around: security service vendors provide GSSAPI, commonly in the form of libraries.'
		]
	},
	{
		paragraph: [
			'The GSSAPI describes less than 50 procedure calls. The main procedures are:',
			'GSS_Acquire_cred',
			"Obtains the user's identity proof, often a secret cryptographic key",
			'GSS_Import_name ',
			'Converts a username or hostname into a form that identifies a security entity ',
			'GSS_Init_sec_context ',
			'Generates a client token to send to the server, usually a challenge',
			'GSS_Accept_sec_context ',
			'Processes a token from GSS_Init_sec_context and can generate a response token to return',
			'GSS_Wrap',
			'Converts application data into a secure message token (typically encrypted)',
			'GSS_Unwrap',
			'Converts a secure message token back into application data',
			'The GSSAPI is standardized for the programming language C (mentioned in the RFC 2744) and Java implements the GSSAPI as JGSS.'
		]
	},
	{
		normalHeading: ['Quantum-Resistant GSS-API Key Exchange for SSH'],
		paragraph: [
			'Research is available in the field of Quantum Resistant GSSAPI Key Exchanges, for instance for openSSH in  the  RFC  document  4462.  OpenSSH  stand  for  Secure  Shell  Protocol  (which  is  a  suite  of  secure networking utilities based on the Secure Shell (SSH) protocol, and provides a secure channel over an unsecured network in a clclient–server architecture). '
		]
	},
	{
		normalHeadingLight: ['RFC '],
		paragraph: [
			'RFC stand for Request for Comments, it is a memorandum published by the internet engineering task force (IETF) and since 1969 more than 2000 RFC documents have been created. The RFCs are publicly available.',
			'Related RFC documents for further research are:',
			'RFC 2743 The Generic Security Service API Version 2 update 1',
			'RFC 2744 The Generic Security Service API Version 2: C-Bindings',
			'RFC 1964 The Kerberos 5 GSS-API mechanism',
			'RFC 4121 The Kerberos 5 GSS-API mechanism: Version 2',
			'RFC 4178 The Simple and Protected GSS-API Negotiation Mechanism (SPNEGO) ',
			'RFC 2025 The Simple Public-Key GSS-API Mechanism (SPKM)',
			'RFC 2847 LIPKEY - A Low Infrastructure Public Key Mechanism Using SPKM ',
			'RFC 8009 AES Encryption with HMAC-SHA2 for Kerberos 5 '
		]
	},
	{
		normalHeadingLight: ['NIST '],
		paragraph: [
			'NIST  stand  for  National  Institute  of  Standards  and  Technology.  It  is  an  agency  oft  he  United  States Department.  NIST  allowed  the  National  Security  Agency  (NSA)  to  insert  a  cryptographically  secure pseudorandom  number  generator  called  Dual  EC  DRBG  into  NIST  standard  SP  800-90  that  had  a kleptographic backdoor that the NSA can use to covertly predict the future outputs of this pseudorandom number generator thereby allowing the surreptitious decryption of data.'
		]
	},
	{
		normalHeadingLight: ['IETF '],
		paragraph: [
			'IETF stand for internet engineering task force. The Internet Engineering Task Force (IETF) is a standards organization  for  the  Internet,  supported  by  the  federal  government  of  the  United  States  and  is responsible for the technical standards that make up the Internet protocol suite (TCP/IP). '
		]
	},
	{
		normalHeadingLight: ['XYNSS'],
		paragraph: [
			'Implementation of the eXtended Naor-Yung Signature Scheme. It was specifically designed for use in blockchain technologies.',
			'XNYSS uses a modified form of Naor-Yung chaining to transform a One-Time Signature (OTS) scheme into a many-time scheme. This is achieved by including the hashes of two public keys, to be used for future signatures, in every created signature. Thus every signature in the resulting binary tree can be traced back to the first one.'
		]
	}
]
