import React from 'react'
import styled from 'styled-components'
import {Container} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'

const GamePage = () => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	return (
		<>
			<Wrapper fluid></Wrapper>
		</>
	)
}
const Wrapper = styled(Container)`
	background: url(/images/project_img/blocland_cover.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	position: relative;
	z-index: 2;
	height: calc(100vh - 148.7px);
`

export default GamePage
