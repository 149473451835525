import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import Button from './Button'
import { useSelector, useDispatch } from 'react-redux'
import { isOpenGameExitModal, openUrlAfterGameExitModal } from '../actions/authActions'
import { MdOutlineClose } from 'react-icons/md'

const WebVersion = () => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	const dispatch = useDispatch()
	const gameStatus = useSelector((state) => state.auth.isGameInProgress)

	useEffect(() => {
		if (!gameStatus) {
			// goBack()
		}
	}, [gameStatus])

	history.pushState(null, null, null)

	window.addEventListener('popstate', () => {
		history.pushState(null, null, null)
	})

	return (
		<Wrapper>
			<CustomRow>
				<CustomCol lg={12}>
					<Container>
						<Form isDarkTheme={isDarkTheme} justifyContent='center'>
							<CustomCol>
								<Ifram
									scrolling="no"
									allow="autoplay; payment; fullscreen; microphone; focus-without-user-activation *; screen-wake-lock; gamepad; clipboard-read; clipboard-write;"
									src={'https://game.blocland-web3.com/'}
									isDarkTheme={isDarkTheme}
									allowfullscreen
									isCentered
									sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts allow-same-origin allow-popups"
									importance="high"
									data-hj-allow-iframe="true"
								/>
								<Back>
									<Close
										onClick={() => {
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/'))) : ''
										}}
									/>
								</Back>
							</CustomCol>
						</Form>
					</Container>
				</CustomCol>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	padding-top: 1rem;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		justify-content: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const CustomCol = styled(Col)`
	height: calc(100vh - 7rem);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
	width: fit-content;
`

const Form = styled(Row)`
	position: relative;
	// background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	border-radius: 0.5rem;
	// box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
`
const Ifram = styled.iframe`
	width: 960px;
	height: 800px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Back = styled.div`
	position: absolute;
	right: -1rem;
	top: 1rem;
`

const Close = styled(MdOutlineClose)`
	color: ${palette.dark};
	background-color: ${palette.aqua_blue_bright};
	font-size: 1.5rem;
	cursor: pointer;
	z-index: 1;
`

export default WebVersion
