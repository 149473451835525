import axios from 'axios'
import {api} from './config/config'

export const viewAllPackege = async () => {
	let response = []
	// const _token = sessionStorage.getItem('authorization')
	// console.log(_token)
	// const config = {
	// 	headers: {authorization: `bearer ${_token}`}
	// }
	await axios
		.get(`${api}/user/packege_list`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const buyNewPackege = async (data) => {
	let response = []
	const _token = sessionStorage.getItem('auth_token')
	const config = {
		headers: {authorization: `bearer ${_token}`}
	}

	await axios
		.post(`${api}/user/buy_packege`, data, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getUserProfile = async () => {
	let response = []
	const _token = sessionStorage.getItem('auth_token')
	const config = {
		headers: {authorization: `bearer ${_token}`}
	}

	await axios
		.get(`${api}/user/user_profile`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
