import React, {useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import Button from '../components/Button'
import {useSelector} from 'react-redux'
import {MDBDataTableV5} from 'mdbreact'
import {Flexed, Spacer, Text} from '../styles/shared'
import {palette} from '../assets/common'

const Packages = () => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	const [packageList, setPackageList] = useState({
		columns: [
			{label: 'Title', field: 'title', width: 470, sort: String('disabled')},
			{label: 'Reward', field: 'price', width: 470, sort: String('disabled')},
			{label: 'Expected SSST', field: 'coins', width: 470, sort: String('disabled')},
			{label: 'Action', field: 'action', width: 150, sort: String('disabled')}
		],
		rows: [
			{
				// id: '1',
				title: 'Purchase with 0.1 BNB',
				price: '1 Free Mint\n(for any game)',
				coins: '5000-7000',
				...(isDarkTheme
					? {
							action: (
								<Button
									type="gray"
									ifClicked={() => {
										buySSST()
									}}
									isDarkTheme={isDarkTheme}
									label={'Buy SSST'}
								/>
							)
					  }
					: {
							action: (
								<Button
									type="gray"
									ifClicked={() => {
										buySSST()
									}}
									secondary
									isDarkTheme={isDarkTheme}
									label={'Buy SSST'}
								/>
							)
					  })
			},
			{
				// id: '2',
				title: 'Purchase with 0.2 BNB',
				price: '3 Free Mint\n(for any game)',
				coins: '5000-7000',
				...(isDarkTheme
					? {
							action: (
								<Button
									type="gray"
									ifClicked={() => {
										buySSST()
									}}
									isDarkTheme={isDarkTheme}
									label={'Buy SSST'}
								/>
							)
					  }
					: {
							action: (
								<Button
									type="gray"
									ifClicked={() => {
										buySSST()
									}}
									secondary
									isDarkTheme={isDarkTheme}
									label={'Buy SSST'}
								/>
							)
					  })
			}
		]
	})
	const buySSST = async () => {
		window.open('https://pancake.kiemtienonline360.com/#/swap')
	}

	return (
		<Wrapper>
			<CustomRow>
				<Col lg={12}>
					<Container>
						<Form isDarkTheme={isDarkTheme}>
							<Col lg={12}>
								<Heading isDarkTheme={isDarkTheme}>Dashboard</Heading>
								<Spacer height={1} />
								{/* <PackageTable
									responsive={true}
									info={false}
									btn={true}
									theadTextWhite={isDarkTheme}
									tbodyTextWhite={isDarkTheme}
									noBottomColumns={true}
									hover={!isDarkTheme}
									paging={false}
									data={packageList}
									searchTop={false}
									searchBottom={false}
								/> */}
							</Col>
						</Form>
					</Container>
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		justify-content: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const Form = styled(Row)`
	padding: 2rem 2rem 3rem 2rem;
	margin-bottom: 2rem;
	border-radius: 0.5rem;
	box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
	${media.xs`
	margin-top: 2rem;
	padding:  0rem;
	`}
	${media.sm`
	margin-top: 2rem;
	padding:  0rem;
	`}
	${media.md`
	margin-top: 0rem;
	padding: 2rem 2rem 3rem 2rem;
	`}
`

const PackageTable = styled(MDBDataTableV5)`
	@media screen and (min-width: 410px) and (max-width: 439px) {
		width: 22rem;
	}
	@media screen and (min-width: 440px) and (max-width: 499px) {
		width: 24rem;
	}
`

const Heading = styled.h2`
	font-family: 'Montserrat-Regular', sans-serif;
	line-height: 1.85rem;
	font-weight: ${({bold}) => (bold ? 'bold' : 'normal')};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.golden : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
`

export default Packages
