import React from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../assets/common'
import {useSelector} from 'react-redux'
import {setConnected, setDisconnected, saveUser} from '../actions/authActions'
import {connect} from 'react-redux'
import {Spacer} from '../styles/shared'

const GamesOverView = () => {
	let user = useSelector((state) => state.auth.user)
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	return (
		<Wrapper>
			<CustomRow>
				<Col lg={12}>
					<Form isDarkTheme={isDarkTheme}>
						<Row>
							<Col lg={12}>
								<Heading isDarkTheme={isDarkTheme}>Games OverViews</Heading>
								<Spacer height={1} />
							</Col>
						</Row>
					</Form>
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		justify-content: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const Form = styled(Container)`
	padding: 2rem;
	${media.xs`
	padding:  2rem 0rem;
	`}
`

const Heading = styled.h2`
	font-family: 'Montserrat-Regular', sans-serif;
	line-height: 1.85rem;
	font-weight: ${({bold}) => (bold ? 'bold' : 'normal')};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.golden : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
`

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => ({
	setConnected: (data) => dispatch(setConnected(data)),
	setDisconnected: (data) => dispatch(setDisconnected(data)),
	saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GamesOverView)
