import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import Button from './Button'
import { useSelector } from 'react-redux'
import { Text, Spacer, Flexed } from '../styles/shared'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const MobileVersion = ({ goBack, gameDetails }) => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	let navigate = useNavigate()

	const goToLogin = async () => {
		//todo..if login then show tick mark otherwise redictt it to login
	}

	const buySSST = async () => {
		navigate('/mobile_tokens')
	}

	const downloadAPK = () => {
		alert('')
	}

	return (
		<Wrapper>
			<CustomRow>
				<Col lg={12}>
					<Form isDarkTheme={isDarkTheme}>
						<Row>
							<Col>
								<Heading isDarkTheme={isDarkTheme}>How to play {gameDetails?.gameName} on mobile</Heading>
							</Col>
						</Row>
						<Spacer height={3} />
						<Row justifyContent="center">
							<Card
								xs={12}
								sm={6}
								md={4}
								lg={4}
								xl={3}
								onClick={() => {
									goToLogin()
								}}>
								<Media>
									<Image src="/images/create_account.png" />
								</Media>
								<Text bold small isCentered isDarkTheme={isDarkTheme}>
									1. Account Creation on web via Metamask (Binance Test Network)
								</Text>
							</Card>
							<Card
								xs={12}
								sm={6}
								md={4}
								lg={4}
								xl={3}
								onClick={() => {
									buySSST()
								}}>
								<Media>
									<Image src="/images/m_ssst.png" />
								</Media>
								<Text bold small isCentered isDarkTheme={isDarkTheme}>
									2. Get Mobile Tokens (M-SSST)
								</Text>
							</Card>
							<DownloadWrapper xs={12} sm={6} md={4} lg={4} xl={3}>
								<Media>
									<a href={`${gameDetails.mobileUrl}`} class={isDarkTheme ? 'animated-button-dark' : 'animated-button-light'}>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										Download APK
									</a>
								</Media>
								<Text bold small isCentered isDarkTheme={isDarkTheme}>
									3. Download, Install & Play (It's secure and trust worthy. No security risk at all. No ads integrated.)
								</Text>
							</DownloadWrapper>
						</Row>
						<Row>
							<Col>
								<Heading isDarkTheme={isDarkTheme}>Instructions</Heading>
							</Col>
						</Row>
						<Spacer height={2} />
						<Row justifyContent="center">
							<Col md={10}>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>1.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										Connect via metamask from web. Your secret id and password for mobile games will be automatically generated.
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>2.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										You can find your secret id and password from my profile. (web only)
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>3.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										Login on mobile game with your secret id and password. Your web game state will be synch automatically or vice versa.
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>4.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										You need virtual{' '}
										<Links isDarkTheme={isDarkTheme} to="/mobile_tokens">
											M-SSST{' '}
										</Links>{' '}
										to enjoy all game features on mobile.
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>5.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										<Links isDarkTheme={isDarkTheme} to="/mobile_tokens">
											Convert
										</Links>{' '}
										SSST to{' '}
										<Links isDarkTheme={isDarkTheme} to="/mobile_tokens">
											M-SSST
										</Links>
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>6.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										Right now you can not convert back M-SSST to SSST. (soon you can do it.)
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>7.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										You can change your secret id and password from my profile. (web only)
									</Text>
								</Flexed>
								<Spacer height={2} />
							</Col>
							<Flex>
								<Button isDarkTheme={isDarkTheme} label={'Back'} ifClicked={goBack} />
							</Flex>
						</Row>
					</Form>
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	padding-top:1rem;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		justify-content: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const Heading = styled.h2`
	line-height: 1.85rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.aqua_blue : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 1.5rem;
	font-weight: bold;
`

const Form = styled(Container)`
	padding: 2rem;
`

const Image = styled.div`
	background: ${({ src, isDarkTheme }) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	background-size: contain;
	background-position: center;
	width: 4.75rem;
	height: 4.75rem;
`
const Card = styled(Col)`
	cursor: pointer;
	margin-bottom: 2rem;
`
const DownloadWrapper = styled(Col)`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: 2rem;
`

const Flex = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;
`

const Media = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5rem;
	margin-bottom: 1rem;
`

const Links = styled(Link)`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	}
`

const Point = styled.div`
	display: inline-block;
	margin-right: 0.5rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	font-weight: bold;
`

export default MobileVersion
