import {ethers} from 'ethers'
import axios from 'axios'
import {api, adminAddress} from '../config/config'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import WalletLink from 'walletlink'
import {SSSTokenContract} from '../contracts/SSSTokenContract'
import {getNetworkById} from '../utils/index'
import {toast} from 'react-toastify'
import CryptoJS from 'crypto-js'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const connectWithMetamask = async (setProcessing, setAddress, setIsConnected, handleDisconnect, props, toast) => {
	try {
		// const infuraId = 'd1904153f4824e368e41ecf3ac0d6418'
		const {default: MewConnectImport} = await import('@myetherwallet/mewconnect-web-client')
		const infuraId = 'd1904153f4824e368e41ecf3ac0d6418'

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider,
				options: {
					infuraId
				}
			},
			mewconnect: {
				package: MewConnectImport,
				options: {
					infuraId
				}
			},
			'custom-coinbase': {
				display: {
					logo: '/images/coinbase_wallet.png',
					name: 'Coinbase',
					description: 'Scan with WalletLink to connect'
				},
				package: WalletLink,
				options: {
					appName: 'SSS Games',
					networkUrl: `https://mainnet.infura.io/v3/${infuraId}`,
					chainId: 1
				},
				connector: async (_, options) => {
					const {appName, networkUrl, chainId} = options
					const walletLink = new WalletLink({
						appName
					})
					const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
					await provider.enable()
					return provider
				}
			}
		}

		const web3Modal = new Web3Modal({
			network: 'mainnet',
			cacheProvider: true,
			providerOptions
		})

		const web3 = await web3Modal.connect()
		const provider = new ethers.providers.Web3Provider(web3)
		const signer = provider.getSigner()
		let address = await signer.getAddress()
		address = address.toLowerCase()
		const chainId = await signer.getChainId()
		// const chainId = await provider.getNetwork()
		const _connectedNetwork = await getNetworkById(chainId)

		if (_connectedNetwork) {
			let nonce = await doPreLogin(address, chainId)
			if (nonce) {
				setProcessing(true)
				const signature = await signer.signMessage(nonce.toString())
				const tokenContract = new ethers.Contract(SSSTokenContract.id, SSSTokenContract.abi, signer)
				let user = await doLogin(address, chainId, signature)
				setProcessing(false)
				if (user) {
					sessionStorage.setItem('auth_token', user?.token)
					user.user.total_points = parseFloat(user.user.total_points)
					const isConnected = Boolean(provider && signer)
					setAddress(address)
					if (setIsConnected) {
						setIsConnected(isConnected)
						setAddress(address)
					}
					props.setConnected({
						isConnected: isConnected,
						user: user.user,
						signer: signer,
						address: address,
						tokenContract: tokenContract
					})
					toast.success('Wallet connected successfully')
					web3.on('accountsChanged', (accounts) => {
						if (handleDisconnect) {
							handleDisconnect()
						} else {
							props.setDisconnected()
						}
					})

					web3.on('chainChanged', (chainId) => {
						if (handleDisconnect) {
							handleDisconnect()
						} else {
							props.setDisconnected()
						}
					})

					web3.on('disconnect', (error) => {
						if (handleDisconnect) {
							handleDisconnect()
						} else {
							props.setDisconnected()
						}
					})
				} else {
					toast.error('Server login error. Please try again later')
					props.setConnected({
						isConnected: false
					})
				}
			}
		} else {
			toast.error('Please connect to chain id 97')
			setProcessing(false)
			props.setConnected({
				isConnected: false
			})
		}
	} catch (error) {
		console.log('error', error)
		setProcessing(false)
		props.setConnected({
			isConnected: false
		})
	}
}

const doPreLogin = async (address, chainId) => {
	const response = await axios.post(`${api}/user/pre_login`, {address: address, chainId: chainId})
	if (response.data.status) {
		const nonce = response.data.data
		const bytes = CryptoJS.AES.decrypt(nonce, 'as@jkfhcnv<./[sdsdsds@#*(&@*!^#&@ads@,asdl-ns')
		const decrypted = bytes.toString(CryptoJS.enc.Utf8)
		return decrypted
	} else {
		return false
	}
}

const doLogin = async (address, chainId, signature) => {
	const response = await axios.post(`${api}/user/web_login`, {address: address, chainId: chainId, signature: signature})
	if (response.data.status) {
		return response.data.data
	} else {
		return false
	}
}

const doUpdateUserTokens = async (u_id, amount) => {
	let form = new FormData()
	form.append('u_id', u_id)
	form.append('coins', amount)
	form.append('type', 'add')
	form.append('c_id', '-1')
	const response = await axios.post(`${api}/update_user_coins`, form)
	if (response.data.status) {
		return true
	} else {
		return false
	}
}

const fetchUserSSSTokensBalance = async (address, tokenContract) => {
	let balance = await tokenContract.balanceOf(address)
	balance = ethers.utils.formatEther(balance)
	return balance
}

const convertSSSTokensToMobileTokens = async (address, amount, tokenContract) => {
	try {
		const tokenApproval = await tokenContract.approve(address, ethers.utils.parseUnits(amount).toString())
		await tokenApproval.wait()
		if (tokenApproval) {
			const transaction = await tokenContract.transferFrom(address, adminAddress, ethers.utils.parseUnits(amount).toString())
			const response = await transaction.wait()
			//todo..check how many transferred from events
			console.log(response)
			return response
		} else {
			console.log('there 1 ')
			return false
		}
	} catch (error) {
		console.log('there  2 ' + error)
		return false
	}
}

export {connectWithMetamask, doUpdateUserTokens, fetchUserSSSTokensBalance, convertSSSTokensToMobileTokens}
