import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import Button from '../components/Button'
import {connect, useSelector} from 'react-redux'
import {MDBDataTableV5} from 'mdbreact'
import {Flexed, Spacer, Text} from '../styles/shared'
import {palette} from '../assets/common'
import {buyNewPackege} from '../api'
import {toast} from 'react-toastify'
import {packegeSeeder} from '../utils'

const PackegeBuy = (props) => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)

	const [packageList, setPackageList] = useState({
		columns: [
			{label: 'Title', field: 'title', width: 470, sort: String('disabled')},
			{label: 'Point', field: 'point', width: 470, sort: String('disabled')},
			{label: 'Price (ETH)', field: 'price', width: 470, sort: String('disabled')},
			{label: 'Action', field: 'action', width: 150, sort: String('disabled')}
		],
		rows: []
	})

	const buyPackege = async (data) => {
		if (props?.isConnected && data?.price) {
			let response = await buyNewPackege(data)
			if (response?.status) {
				toast.success(response.reason)
			} else {
				toast.error(response.reason)
			}
		} else {
			toast.error('Please connect wallet first')
		}
	}

	const viewAllPackegeList = async () => {
		// let response = await viewAllPackege()
		setPackageList((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: []
			}
		})
		packegeSeeder?.map((data, index) => {
			createPackegeList(data)
		})
	}

	useEffect(() => {
		viewAllPackegeList()
	}, [props?.isConnected])

	const createPackegeList = (row) => {
		let _obj = {
			title: row.title,
			point: row.point,
			price: row.price + ' ETH',
			action: (
				<Button
					type="success"
					ifClicked={() => {
						if (props?.isConnected == true) {
							buyPackege(row)
						} else {
							toast.error('Please connect wallet first')
						}
					}}
					secondary
					isDarkTheme={isDarkTheme}
					label={'Buy Packege'}
				/>
			)
		}
		setPackageList((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	return (
		<Wrapper>
			<CustomRow>
				<Col lg={12}>
					<Container>
						<Form isDarkTheme={isDarkTheme}>
							<Col lg={12}>
								<Heading isDarkTheme={isDarkTheme}>Our Packages</Heading>
								<Spacer height={1} />
								<PackageTable
									responsive={true}
									info={false}
									btn={true}
									theadTextWhite={isDarkTheme}
									tbodyTextWhite={isDarkTheme}
									noBottomColumns={true}
									hover={!isDarkTheme}
									paging={false}
									data={packageList}
									searchTop={false}
									searchBottom={false}
								/>
							</Col>
						</Form>
					</Container>
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		justify-content: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const Form = styled(Row)`
	padding: 2rem 2rem 3rem 2rem;
	margin-bottom: 2rem;
	border-radius: 0.5rem;
	box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
	${media.xs`
	margin-top: 2rem;
	padding:  0rem;
	`}
	${media.sm`
	margin-top: 2rem;
	padding:  0rem;
	`}
	${media.md`
	margin-top: 0rem;
	padding: 2rem 2rem 3rem 2rem;
	`}
`

const PackageTable = styled(MDBDataTableV5)`
	@media screen and (min-width: 410px) and (max-width: 439px) {
		width: 22rem;
	}
	@media screen and (min-width: 440px) and (max-width: 499px) {
		width: 24rem;
	}
`

const Heading = styled.h2`
	font-family: 'Montserrat-Regular', sans-serif;
	line-height: 1.85rem;
	font-weight: ${({bold}) => (bold ? 'bold' : 'normal')};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.golden : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
`

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		isConnected: state.auth.isConnected
	}
}

export default connect(mapStateToProps)(PackegeBuy)
