import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { connect } from 'react-redux'

import { setConnected, setDisconnected, saveUser, setGameStatus, isOpenGameExitModal, openUrlAfterGameExitModal } from '../actions/authActions'
import { connectWithMetamask } from '../hooks/contracts'

import { toast } from 'react-toastify'
import { palette } from '../assets/common'
import { useNavigate } from 'react-router-dom'
import Button, { handleBackgroundType, handleBorderType } from '../components/Button'
import { useSelector, useDispatch } from 'react-redux'
import { BsFillSunFill, BsMoonFill, BsXLg } from 'react-icons/bs'
import { AiOutlineLoading3Quarters, AiOutlineMenu } from 'react-icons/ai'

import { useLocation } from 'react-router-dom'
import { Flexed, Spacer, Text } from '../styles/shared'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { isTestNet } from '../config/config'

const Header = (props) => {
	const { pathname } = useLocation()
	const navigate = useNavigate()

	const _isConnected = useSelector((state) => state.auth.isConnected)
	const user = useSelector((state) => state.auth.user)
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	const gameStatus = useSelector((state) => state.auth.isGameInProgress)
	const exitGameModalStatus = useSelector((state) => state.auth.isExitGameModalOpen)
	const goToThisUrl = useSelector((state) => state.auth.urlAfterGameExit)

	const dispatch = useDispatch()

	const [isConnected, setIsConnected] = useState(false)
	const [address, setAddress] = useState('')
	const [processing, setProcessing] = useState(false)
	const [navMenu, setNavMenu] = useState(false)
	const [scrollPosition, setScrollPosition] = useState(0)
	const [activeDisconnect, setActiveDisconnect] = useState(false)

	const [size, setSize] = useState({
		x: window.innerWidth,
		y: window.innerHeight
	})

	const updateSize = () =>
		setSize({
			x: window.innerWidth,
			y: window.innerHeight
		})

	useEffect(() => (window.onresize = updateSize), [])

	useEffect(() => {
		if (size.x > 1200) {
			setNavMenu(false)
		}
	}, [size])

	useEffect(() => {
		if (_isConnected) {
			setIsConnected(true)
			setAddress(user.address)
		}
	}, [_isConnected])

	const handleDisconnect = async () => {
		setIsConnected(false)
		sessionStorage.removeItem('auth_token')
		props.setDisconnected()
	}

	const handleConnect = async () => {
		await connectWithMetamask(setProcessing, setAddress, setIsConnected, handleDisconnect, props, toast)
	}

	const handleScroll = () => {
		const _position = window.pageYOffset
		setScrollPosition(_position)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })
	}, [])

	return (
		<>
			<Main
				isDarkTheme={isDarkTheme}
				styled={{
					boxShadow: '0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%)'
				}}
				scroll={scrollPosition}>
				<Row>
					<NavContent>
						<LogoContent
							onClick={() => {
								gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/'))) : navigate('/')
							}}>
							{/* {isTestNet && (
								<BarBadgeWrapper testNetBadge={true} id="ddd">
									<BarBadge src="/images/testnet.svg" testNetBadge={true} />
								</BarBadgeWrapper>
							)} */}
							<Logo src="/images/project_img/logo.png" alt="logo" />
							<LogoText>
								<BrandName isDarkTheme={isDarkTheme}>BLOCLAND</BrandName>
								{/* <TagLine isDarkTheme={isDarkTheme}>
									Coding <span>Your</span> Thoughts
								</TagLine> */}
							</LogoText>
						</LogoContent>

						<Flexed direction="row" align="center">
							<Menu
								isActive={pathname === '/'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/'))) : navigate('/')
								}}>
								Home
							</Menu>
							<Menu
								isActive={pathname === '/game-room'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/game-room'))) : navigate('/game-room')
								}}>
								Game Room
							</Menu>
							{/* <Menu
								isActive={pathname === '/games-overview'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/games-overview'))) : navigate('/games-overview')
								}}>
								Games Overview
							</Menu> */}
							<Menu
								isActive={pathname === '/library'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/library'))) : navigate('/library')
								}}>
								Library
							</Menu>

							<Menu
								isActive={pathname === '/our_packege'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/our_packege'))) : navigate('/our_packege')
								}}>
								Our Packages
							</Menu>
							{/* <Menu
								isActive={pathname === '/dashboard'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/dashboard'))) : navigate('/dashboard')
								}}>
								Dashboard
							</Menu> */}
							<MenuLink isDarkTheme={isDarkTheme} target="_blank" href="https://gate.io">
								Marketplace
							</MenuLink>
							<MenuLink href="https://discord.gg/tyzdMvs3" rel="noreferrer" target="_blank">
								<SocialIcon src="/images/discord.png" />
							</MenuLink>
							<DropMenu isConnected={isConnected}>
								<ButtonWrapper>
									<ConnectButton
										type="success"
										isDarkTheme={isDarkTheme}
										disabled={processing}
										onMouseEnter={() => setNavMenu(false)}
										onClick={() => (!isConnected && !processing ? handleConnect() : gameStatus ? dispatch(isOpenGameExitModal(true), setActiveDisconnect(true)) : handleDisconnect())}>
										{!processing ? (
											<> {!isConnected ? 'Connect with Ether' : address} </>
										) : (
											<span>
												<Processing /> &nbsp;Connecting...
											</span>
										)}
									</ConnectButton>
								</ButtonWrapper>
								<Drop id="dropdown" isDarkTheme={isDarkTheme}>
									{/* <DropList
										isActive={pathname === '/tokens'}
										isDarkTheme={isDarkTheme}
										onClick={() => {
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/tokens'))) : navigate('/tokens')
										}}>
										<Flexed direction="row" align="center">
											<Icon isDarkTheme={isDarkTheme} />
											<Text small bold isDarkTheme={isDarkTheme}>
												SSST
											</Text>
										</Flexed>
										<Text small margin="0.5rem 0rem 0rem 0rem !important" bold isDarkTheme={isDarkTheme}>
											{props.user?.sssTokens ? props.user?.sssTokens : 0} SSST
										</Text>
										<Text small bold color="charcol">
											~0 BNB
										</Text>
									</DropList> */}
									{/* <DropList
										isActive={pathname === '/mobile_tokens'}
										isDarkTheme={isDarkTheme}
										onClick={() => {
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/mobile_tokens'))) : navigate('/mobile_tokens')
										}}>
										<Flexed direction="row" align="center">
											<Icon isDarkTheme={isDarkTheme} />
											<Text small bold isDarkTheme={isDarkTheme}>
												M-SSST
											</Text>
										</Flexed>
										<Text small margin="0.5rem 0rem 0rem 0rem !important" bold isDarkTheme={isDarkTheme}>
											{props.user?.coins ? props.user?.coins : 0} M-SSST
										</Text>
										<Text small bold color="charcol">
											~0 BNB
										</Text>
									</DropList> */}
									<DropList
										isActive={pathname === '/profile'}
										isDarkTheme={isDarkTheme}
										onClick={() => {
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/profile'))) : navigate('/profile')
										}}>
										<Text small bold isDarkTheme={isDarkTheme}>
											My Profile
										</Text>
									</DropList>
									{/* <DropList
										isActive={pathname === '/payment'}
										isDarkTheme={isDarkTheme}
										onClick={() => {
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/payment'))) : navigate('/payment')
										}}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Payment Gateway
										</Text>
									</DropList>
									 */}
									<DropList to={pathname} onClick={() => (!isConnected && !processing ? handleConnect() : gameStatus ? dispatch(isOpenGameExitModal(true), setActiveDisconnect(true)) : handleDisconnect())} isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Disconnect Ether
										</Text>{' '}
									</DropList>
								</Drop>
							</DropMenu>
							{/* <Mode>
								{isDarkTheme ? (
									<Cover
										isDarkTheme={isDarkTheme}
										isActive={isDarkTheme}
										onClick={() => {
											dispatch(switchTheme(false))
										}}>
										<BsFillSunFill />
									</Cover>
								) : (
									<Cover
										isDarkTheme={isDarkTheme}
										isActive={!isDarkTheme}
										onClick={() => {
											dispatch(switchTheme(true))
										}}>
										<BsMoonFill />
									</Cover>
								)}
							</Mode> */}
							<MenuToggle>
								<MenuToggleBtn
									navMenu={navMenu}
									onClick={() => {
										setNavMenu(!navMenu)
									}}
									isDarkTheme={isDarkTheme}>
									{navMenu ? <BsXLg /> : <AiOutlineMenu />}
								</MenuToggleBtn>
								<MenuDropDown id="nav_responsive_menu" isDarkTheme={isDarkTheme} navMenu={navMenu}>
									<DropList
										onClick={() => {
											setNavMenu(false)
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/'))) : navigate('/')
										}}
										isActive={pathname === '/'}
										isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Home
										</Text>
									</DropList>
									<DropList
										onClick={() => {
											setNavMenu(false)
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/game-room'))) : navigate('/game-room')
										}}
										isActive={pathname === '/game-room/game-room'}
										isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Game Room
										</Text>
									</DropList>
									<DropList
										onClick={() => {
											setNavMenu(false)
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/library'))) : navigate('/library')
										}}
										isActive={pathname === '/library'}
										isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Library
										</Text>
									</DropList>
									<DropList
										onClick={() => {
											setNavMenu(false)
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/dashboard'))) : navigate('/dashboard')
										}}
										isActive={pathname === '/dashboard'}
										isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Dashboard
										</Text>
									</DropList>
									<DropListLink
										target="_blank"
										href="https://gate.io"
										isDarkTheme={isDarkTheme}
										onClick={() => {
											setNavMenu(false)
										}}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Marketplace
										</Text>{' '}
									</DropListLink>
									<DropListLink
										target="_blank"
										href="https://discord.gg/tyzdMvs3"
										isDarkTheme={isDarkTheme}
										onClick={() => {
											setNavMenu(false)
										}}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Discord
										</Text>{' '}
									</DropListLink>
								</MenuDropDown>
							</MenuToggle>
						</Flexed>
					</NavContent>
				</Row>
			</Main>
			<Modal
				open={exitGameModalStatus}
				onClose={() => {
					dispatch(isOpenGameExitModal(false))
				}}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal',
					closeButton: 'closeModel'
				}}
				closeOnOverlayClick={false}>
				<Spacer height={3} />
				<Container>
					<Row>
						<Col>
							<Text isDarkTheme={isDarkTheme} isCentered>
								Game is in progress.
								<br /> Are you sure you want to Exit ?
							</Text>
							<Spacer height={2} />
						</Col>

						<Col>
							<Flexed direction="row" justify="space-around">
								<Button
									type="blue"
									isDarkTheme={isDarkTheme}
									label="No"
									ifClicked={() => {
										dispatch(isOpenGameExitModal(false))
									}}
								/>
								<Button
									type="red"
									isDarkTheme={isDarkTheme}
									label="Yes"
									ifClicked={() => {
										dispatch(setGameStatus(false))
										dispatch(isOpenGameExitModal(false))
										activeDisconnect ? handleDisconnect() : navigate(goToThisUrl)
									}}
								/>
							</Flexed>
						</Col>
					</Row>
				</Container>
			</Modal>
		</>
	)
}

const Main = styled(Container)`
	position: fixed;
	background: ${({ scroll }) => (scroll == 0 ? 'rgba(4, 6, 9, 1)' : 'rgb(4, 6 , 9, 0.8)')};
	z-index: 9;
	max-width: 100%;
	box-shadow: 0px 3px 15px rgba(255, 255, 255, 0.2);
`

const NavContent = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 5rem;
	padding: 0rem 3rem 0rem 3rem;
	${media.xs`
    padding: 0rem 1rem 0rem 1rem;
  `}
	${media.sm`
    padding: 0rem 1rem 0rem 1rem;
  `}
`

const LogoContent = styled.div`
	padding: 0rem;
	display: flex;
	align-items: center;
`
const Logo = styled.img`
	width: 5rem;
	padding: 0rem;
	cursor: pointer;
`

const LogoText = styled.span`
	margin-left: 0.5rem;
	cursor: pointer;
	${media.xs`
    	display:none;
  `}
	${media.sm`
   		display:block;
   `}
`

const BrandName = styled.p`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.golden}` : `${palette.black}`)};
	font-size: 1.4rem;
	font-weight: bold;
	font-family: 'bicubik';
	display: flex;
	justify-content: center;
	alignc-items: center;
	letter-spacing: 0.2rem;
	margin-top: -0.3rem;
	& span {
		color: ${palette.aqua_blue};
	}
`
const TagLine = styled.p`
	color: ${palette.aqua_blue};
	font-family: 'bicubik';
	font-size: 0.5rem;
	margin-top: -1.2rem;
	letter-spacing: 0.42rem;
	text-transform: uppercase;
	text-align: center;
	& span {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.black}`)};
	}
`
const ButtonWrapper = styled.div`
	position: relative;
	transition: all 0.5s ease-in-out;
	width: fit-content;
	&::before {
		position: absolute;
		transform: matrix(1, 0.05, 0.01, 1, 0, 0);
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0;
		background: ${palette.green_gradient_shadow};
		filter: blur(7.60982px);
		border-radius: 2rem;
	}
	&:hover {
		&::before {
			background: none;
			filter: blur(7.60982px);
			border-radius: 2rem;
		}
	}
`

const ConnectButton = styled.div`
	position: relative;
	font-family: 'bicubik';
	letter-spacing: 0.15em;
	font-size: 0.8rem;
	background: ${({ type, isDarkTheme }) => (isDarkTheme ? `${palette.black2}` : handleBackgroundType(type))};
	display: inline-block;
	margin: 0.4rem 0.5rem 0 0.5rem;
	padding: 0rem 1rem;
	height: 40px;
	line-height: 40px;
	font-weight: 600;
	border: 1px solid ${({ type }) => handleBorderType(type)};
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.white}`)};
	border-radius: 2rem;
	text-align: center;
	cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
	text-overflow: ellipsis;
	overflow: hidden;
	min-width: 8.75rem;
	max-width: 15rem;
	white-space: nowrap;
	transition: all 0.5s ease-in-out;
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.white}`)};
		background: ${({ isDarkTheme, type }) => (isDarkTheme ? handleBackgroundType(type) : `${palette.off_black}`)};
	}
`
const spinnerAnimation = keyframes`
	to { -webkit-transform: rotate(360deg); }
`

const Processing = styled(AiOutlineLoading3Quarters)`
	animation: ${spinnerAnimation} 1.5s linear infinite;
`

const Menu = styled.div`
	color: ${({ isDarkTheme, isActive }) => (isActive == true ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	text-decoration: none;
	padding: 0rem 0.5rem;
	cursor: pointer;
	font-family: 'Montserrat-Medium', sans-serif;
	text-transform: uppercase;
	font-size: 0.9rem;
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	}
	${media.xs`
		display: none;
    `};
	${media.sm`
		display: none;
    `};
	${media.md`
		display: none;
    `};
	${media.lg`
		display: none;
    `};
	@media screen and (min-width: 1300px) and (max-width: 9999px) {
		display: block;
	}
`
const MenuLink = styled.a`
	color: ${({ isDarkTheme, isActive }) => (isActive == true ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	text-decoration: none;
	padding: 0rem 0.5rem;
	cursor: pointer;
	font-family: 'Montserrat-Medium', sans-serif;
	text-transform: uppercase;
	font-size: 0.9rem;
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	}
	${media.xs`
		display: none;
    `};
	${media.sm`
		display: none;
    `};
	${media.md`
		display: none;
    `};
	${media.lg`
		display: none;
    `};
	@media screen and (min-width: 1300px) and (max-width: 9999px) {
		display: block;
	}
`

const Mode = styled.div`
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	cursor: pointer;
`

const Cover = styled.div`
	padding: 0.04rem 0.28rem;
	font-size: 1.2rem;
	border-radius: 0.2rem;
	color: ${({ isDarkTheme, isActive }) => (isDarkTheme ? (isActive ? `${palette.aqua_blue}` : `${palette.yellow}`) : isActive ? `${palette.blue}` : `${palette.off_dark}`)};
	// background: ${({ isDarkTheme, isActive }) => (isDarkTheme ? (isActive ? `${palette.green_gradient}` : `${palette.black}`) : isActive ? `${palette.blue_mirror}` : `${palette.white}`)};
	cursor: pointer;
`

const DropMenu = styled.div`
	position: relative;
	&:hover #dropdown {
		display: ${({ isConnected }) => (isConnected ? `block` : `none`)};
	}
`

const Drop = styled.div`
	display: none;
	position: absolute;
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	min-width: 16rem;
	padding: 0.5rem;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	right: 0;
	border-radius: 1.2rem;
`

const DropList = styled.div`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	border-radius: 1.2rem;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
	margin-bottom: 0.2rem;
	font-weight: bold;
	background: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};

	& p {
		color: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
		cursor: pointer;
	}

	&:hover {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`)};
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
		cursor: pointer;
	}
	&:hover p {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
		cursor: pointer;
	}
`

const DropListLink = styled.a`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	border-radius: 1.2rem;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
	margin-bottom: 0.2rem;
	font-weight: bold;
	background: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};

	& p {
		color: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
		cursor: pointer;
	}

	&:hover {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`)};
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
		cursor: pointer;
	}
	&:hover p {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
		cursor: pointer;
	}
`

const Icon = styled.div`
	background: ${({ src, isDarkTheme }) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	background-size: cover;
	background-position: center;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 5rem;
	border: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	margin-right: 0.3rem;
`

const MenuToggle = styled.div`
	width: fit-content;
	position: relative;
	// display: none;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	cursor: pointer;
	${media.xs`
		display: flex;
    `};
	${media.sm`
		display: flex;
    `};
	${media.md`
		display: flex;
    `};
	${media.lg`
		display: flex;
    `};
	@media screen and (min-width: 1300px) and (max-width: 9999px) {
		display: none;
	}
`

const MenuToggleBtn = styled.div`
	padding: 0.04rem 0.28rem;
	font-size: ${({ navMenu }) => (navMenu ? '1.3rem' : '1.5rem')};
	cursor: pointer;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
`

const MenuDropDown = styled.div`
	display: ${({ navMenu }) => (navMenu ? 'block' : 'none')};
	position: absolute;
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	min-width: 16rem;
	padding: 0.5rem;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	right: 0;
	top: 3rem;
	border-radius: 1.2rem;
`

const BarBadgeWrapper = styled.div`
	position: absolute;
	right: ${({ testNetBadge }) => (testNetBadge ? `` : `0rem`)};
	top: ${({ testNetBadge }) => (testNetBadge ? `` : `-0.188rem`)};
	transform: ${({ testNetBadge }) => (testNetBadge ? `rotate(0deg)` : `rotate(90deg)`)};
	left: -3px;
`

const BarBadge = styled.img`
	width: ${({ testNetBadge }) => (testNetBadge ? `100%` : `4.5rem`)};
`

const SocialIcon = styled.img`
	height: 1.5rem;
	margin-right: 0.5rem;
	width: 1.5rem;
	filter: invert(40%) sepia(13%) saturate(1398%) hue-rotate(264deg) brightness(85%) contrast(92%);
`

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => ({
	setConnected: (data) => dispatch(setConnected(data)),
	setDisconnected: (data) => dispatch(setDisconnected(data)),
	saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
