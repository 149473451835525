import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, media} from 'styled-bootstrap-grid'
import {palette} from '../assets/common'
import Button from '../components/Button'
import {useSelector, useDispatch} from 'react-redux'
import {Flexed, Text, Spacer} from '../styles/shared'
import {AiTwotoneCheckCircle} from 'react-icons/ai'
import {setGameStatus} from '../actions/authActions'
import GamePage from '../components/GamePage'
import InfoContent from '../components/InfoContent'
import WebVersion from '../components/WebVersion'
import MobileVersion from '../components/MobileVersion'

const featureLabels = {new: 'new', hot: 'hot', revised: 'revised'}

const Home = () => {
	const dispatch = useDispatch()
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	const [isHome, setIsHome] = useState(true)
	const [isWeb, setIsWeb] = useState(false)
	const [isMobile, setIsMobile] = useState(false)

	const [size, setSize] = useState({
		x: window.innerWidth,
		y: window.innerHeight
	})

	const updateSize = () =>
		setSize({
			x: window.innerWidth,
			y: window.innerHeight
		})

	useEffect(() => (window.onresize = updateSize), [])

	return (
		<>
			{isHome && (
				<>
					<GamePage />
				</>
			)}

			{isWeb && (
				<WebVersion
					goBack={() => {
						setIsHome(true)
						setIsWeb(false)
					}}
					gameDetails={isWeb}
				/>
			)}
			{isMobile && (
				<MobileVersion
					goBack={() => {
						setIsHome(true)
						setIsMobile(false)
					}}
					gameDetails={isMobile}
				/>
			)}
		</>
	)
}

export default Home
